/* eslint-disable jsx-a11y/anchor-is-valid */
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { createRef, useState, useEffect } from 'react';
import { Fragment } from 'react'
import interactionPlugin from '@fullcalendar/interaction';
import './CalendarStyle.css';
import moment from "moment"
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,

  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import { Menu, Transition, Dialog } from '@headlessui/react'
function renderEventContent(eventInfo) {


  return (
    <>
      <b>{eventInfo.timeText}{eventInfo.timeText ? "-" : ""}  </b>
      <i>{eventInfo.event.title}</i>

    </>
  )
}
const calendarRef = createRef()
const eventObjData = {
  eventName: "",
  startDate: "",
  endDate: "",
  id: ""
}

const Calendar = (props) => {
  
  const [eventArray, setEventArray] = useState(props.events);
  const [eventStyling, setEventStyling] = useState(props.stylingAndOthers);
  const [state, setstate] = useState();
  const [open, setOpen] = useState(false)
  const [eventObj, setEventObj] = useState(eventObjData);
  
  const [addEdit, setAddEdit] = useState('');
  const [state2, setstate2] = useState("");
  const [view, setView] = useState("Month view");
  
  useEffect(() => {
    const data = calendarRef.current.getApi()

    setstate(data)

  }, [props]);
  useEffect(() => {
    

    setEventArray(props.events)

  }, [props]);
  const dateClickEvent = (e) => {



  }

  // useEffect(() => {
  //   const data = calendarRef.current.getApi()
  //   setstate(data)
  // }, []);
  const eventClickHandler = (e) => {
    setAddEdit('edit')
    const startDate = moment(new Date(e?.event?.start)).format('YYYY-MM-DDTHH:mm')

    let endDate = ''
    if (e?.event?.end !== null) {
      endDate = moment(new Date(e?.event?.end)).format('YYYY-MM-DDTHH:mm')
    }




    // let dateStartConversion = startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
    // dateStartConversion=dateStartConversion?.toISOString().slice(0, 16)
    const eventObj = {
      eventName: e?.event?.title,
      startDate: startDate,
      endDate: endDate,
      id: e?.event.id ? e?.event.id : "",
      event: e
    }


    setEventObj((latest) => {
      return eventObj
    })
    setOpen(true)
    const check = calendarRef.current.getApi()

  }
  const eventMouseEnterHandler = (e) => {
    // console.log("Mouse enter");
    // console.log(e)

  }
  const eventMouseLeaveHandler = (e) => {
    //    console.log('Mouse leave');
    //     console.log(e)

  }
  const changePrev = (e) => {

    state.prev()

    setstate2(Math.random().toString())



    // setstate(state.prev())
    // setstate(check)

  }
  const changeNext = (e) => {

    state.next()

    setstate2(Math.random().toString())





  }

  const addEvent = () => {
    setEventObj(eventObjData)
    setAddEdit('add')
    setOpen(true)
  }
  const changeView = (e) => {

    switch (e?.target?.text) {
      case "Day view":

        calendarRef.current
          .getApi()
          .changeView('timeGridDay')
        setView("Day view")
        break;
      case "Month view":
        calendarRef.current
          .getApi()
          .changeView('dayGridMonth')
        setView("Month view")
        break;
      case "Year view":
        calendarRef.current
          .getApi()
          .changeView('dayGridYear')

        setView("Year view")
        break;
      case "Week view":
        calendarRef.current
          .getApi()
          .changeView('timeGridWeek')
        setView("Week view")
        break;
      default:
        break;
    }




  }
  const changeToday = (e) => {


    state.today()

    setstate2(Math.random().toString())





  }
  const eventChangeHandler = (e) => {




    


  }

  const saveEvent = () => {
    

    const data = calendarRef.current.getApi()

    if (eventObj.id !== "") {
      let newEventObj = {
        title: eventObj.eventName,
        start: eventObj.startDate,
        "id": Math.random().toString()
      }
      const checkData = state.getEventById(eventObj.id)
      checkData.setProp('title', eventObj.eventName)
      if (eventObj.endDate === "") {
        checkData.setDates(eventObj.startDate)
      }
      else {
        newEventObj = { ...newEventObj, end: new Date(eventObj.endDate) }
        checkData.setDates(eventObj.startDate, eventObj.endDate)
      }
      props.setEdit(newEventObj)


    } else {
      let newEventObj = {
        title: eventObj.eventName,
        start: eventObj.startDate,
        "id": Math.random().toString()
      }
      if (eventObj.endDate !== "") {
        newEventObj = { ...newEventObj, end: new Date(eventObj.endDate) }
      }
       
      data.addEvent(newEventObj)
      props.setAdd(newEventObj)
    }


    
    setOpen(false)
   
  }

  const deleteEvent = () => {
    const checkData = state.getEventById(eventObj.id)
    
    const deleteObj={
      start:checkData.start,
      end:checkData.end,
      id:checkData.id,
      title:checkData.title
    }
    checkData.remove()
    props.setDelete(deleteObj)
    setOpen(false)
  }

  const changeStartDate = (e) => {

    setEventObj((latest) => {

      return { ...latest, "startDate": e.target.value }
    })
  }
  const changeEndDate = (e) => {
    setEventObj((latest) => {

      return { ...latest, "endDate": e.target.value }
    })
  }
  const changeEventName = (e) => {
    setEventObj((latest) => {

      return { ...latest, "eventName": e.target.value }
    })
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <div className="w-3/4 m-auto py-6 h-96 rounded">
      <div className="lg:flex  lg:flex-col">
        <header className="flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none bg-gray-100">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime="2022-01">{state?.currentData?.viewTitle}</time>
          </h1>
          <div className="flex items-center">
            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
              <button
                type="button"
                onClick={changePrev}
                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={changeToday}
                className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                onClick={changeNext}
                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <Menu as="div" className="relative">
                <Menu.Button
                  type="button"
                  className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {view}
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items onClick={changeView} className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a

                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Day view
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Week view
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Month view
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Year view
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                type="button"
                onClick={addEvent}
                className="ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add event
              </button>
            </div>
            <Menu as="div" className="relative ml-6 md:hidden">
              <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Create event
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Go to today
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Day viewft
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Week view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Month view
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                       
                        <a
                          href="#"
                          
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Year view
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </header>
        {/* <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                  <div className="bg-white py-2">
                    M<span className="sr-only sm:not-sr-only">on</span>
                  </div>
                  <div className="bg-white py-2">
                    T<span className="sr-only sm:not-sr-only">ue</span>
                  </div>
                  <div className="bg-white py-2">
                    W<span className="sr-only sm:not-sr-only">ed</span>
                  </div>
                  <div className="bg-white py-2">
                    T<span className="sr-only sm:not-sr-only">hu</span>
                  </div>
                  <div className="bg-white py-2">
                    F<span className="sr-only sm:not-sr-only">ri</span>
                  </div>
                  <div className="bg-white py-2">
                    S<span className="sr-only sm:not-sr-only">at</span>
                  </div>
                  <div className="bg-white py-2">
                    S<span className="sr-only sm:not-sr-only">un</span>
                  </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                  <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                    {days.map((day) => (
                      <div
                        key={day.date}
                        className={classNames(
                          day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                          'relative py-2 px-3'
                        )}
                      >
                        <time
                          dateTime={day.date}
                          className={
                            day.isToday
                              ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                              : undefined
                          }
                        >
                          {day.date.split('-').pop().replace(/^0/, '')}
                        </time>
                        {day.events.length > 0 && (
                          <ol className="mt-2">
                            {day.events.slice(0, 2).map((event) => (
                              <li key={event.id}>
                                <a href={event.href} className="group flex">
                                  <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                    {event.name}
                                  </p>
                                  <time
                                    dateTime={event.datetime}
                                    className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                                  >
                                    {event.time}
                                  </time>
                                </a>
                              </li>
                            ))}
                            {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                          </ol>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                    {days.map((day) => (
                      <button
                        key={day.date}
                        type="button"
                        className={classNames(
                          day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                          (day.isSelected || day.isToday) && 'font-semibold',
                          day.isSelected && 'text-white',
                          !day.isSelected && day.isToday && 'text-indigo-600',
                          !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                          !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                          'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                        )}
                      >
                        <time
                          dateTime={day.date}
                          className={classNames(
                            day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                            day.isSelected && day.isToday && 'bg-indigo-600',
                            day.isSelected && !day.isToday && 'bg-gray-900',
                            'ml-auto'
                          )}
                        >
                          {day.date.split('-').pop().replace(/^0/, '')}
                        </time>
                        <span className="sr-only">{day.events.length} events</span>
                        {day.events.length > 0 && (
                          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            {day.events.map((event) => (
                              <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                            ))}
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {selectedDay?.events.length > 0 && (
                <div className="py-10 px-4 sm:px-6 lg:hidden">
                  <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                    {selectedDay.events.map((event) => (
                      <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                        <div className="flex-auto">
                          <p className="font-semibold text-gray-900">{event.name}</p>
                          <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {event.time}
                          </time>
                        </div>
                        <a
                          href={event.href}
                          className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                        >
                          Edit<span className="sr-only">, {event.name}</span>
                        </a>
                      </li>
                    ))}
                  </ol>
                </div>
              )} */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div> */}

                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {addEdit === "add" ? 'Add Event' : "Edit Event"}
                      </Dialog.Title>
                      <form>
                        <div className='space-x-12 flex'>
                          <div >
                            <label htmlFor="first-name" className="block text-left text-sm font-medium leading-6 text-gray-900">
                              Event name
                            </label>
                            <input
                              type="text"
                              name="first-name"
                              value={eventObj.eventName}
                              onChange={changeEventName}
                              id="first-name"
                              autoComplete="given-name"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>



                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 md:space-x-3'>
                          <div  >
                            <label htmlFor="last-name" className="block text-left text-sm font-medium leading-6 text-gray-900">
                              Start Date
                            </label>
                            <input
                              type="datetime-local"
                              name="last-name"
                              value={eventObj.startDate}
                              onChange={changeStartDate}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div >
                            <label htmlFor="last-name" className="block text-left text-sm font-medium leading-6 text-gray-900">
                              End Date
                            </label>
                            <input
                              type="datetime-local"
                              name="last-name"
                              value={eventObj?.endDate}
                              onChange={changeEndDate}
                              id="last-name"
                              autoComplete="family-name"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                      </form>


                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                        onClick={saveEvent}
                      >
                        Save
                      </button>
                    </div>
                    {
                      addEdit === "edit" ?

                        <div className="mt-2 sm:mt-2">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                            onClick={deleteEvent}

                          >
                            Delete
                          </button>
                        </div>
                        :
                        ""
                    }
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
      <FullCalendar ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        height={eventStyling.height}

        
        dateClick={dateClickEvent}
        eventClick={eventClickHandler}
        eventMouseEnter={eventMouseEnterHandler}
        eventMouseLeave={eventMouseLeaveHandler}
        eventContent={renderEventContent}
        initialView={eventStyling.initialView}
        weekends={eventStyling.weekends}
        hiddenDays={eventStyling.hiddenDays}

        eventChange={eventChangeHandler}
      
        dayMaxEvents={eventStyling.dayMaxEvents}
        dayHeaderClassNames={eventStyling.dayHeaderClassNames}
        
        dayHeaders={eventStyling.dayHeaders}
        dayHeaderFormat={eventStyling.dayHeaderFormat}
        events={eventArray}


        eventColor={eventStyling.eventColor}
      />


      
    </div>
    
  )
}

export default Calendar