import { Fragment, useState } from "react";

const ZColumnIcon = ({ IconList, existingIcon, triggerSelectedIcon }) => {
  const [selectedIcon, setSelectedIcon] = useState(existingIcon ? existingIcon : '');
  const onSelectIcon = (e) => () => {
    setSelectedIcon(e);
    triggerSelectedIcon(e);
  };
  return (
    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="relative grid grid-cols-8 bg-white px-4 py-2 sm:gap-8">
        {IconList.map((item, index) => (
          <div
            onClick={onSelectIcon(item.icon.default)}
            className={`w-8 p-2 flex items-center justify-center cursor-pointer rounded-lg hover:bg-lightRed ${
              selectedIcon == item.icon.default ? "bg-lightRed" : ""
            }`}
            key={`columicon--${index}`}
          >
            <img className="w-5 h-2.5" src={item.icon.default} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZColumnIcon;
