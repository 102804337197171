import { Fragment } from "react"
import Calendar from "./Calendar"
import { useState } from "react"
const events = [
    { title: 'event 1', start: new Date(2023, 4, 1), textColor: "#FFF000", allDay: true , id: "2"},
    { title: 'event 7', start: new Date(2023, 4, 1), end: new Date(2023, 4, 10), textColor: "#FFF000", id: "4" },
    { title: 'event 10', start: new Date(2023, 4, 1), end: new Date(2023, 4, 10), textColor: "#FFF000" ,id:"5"},
    { title: 'event 6', date: '2023-04-01', id: "1" },
    { title: 'event 2', date: '2023-04-02' ,id:"6"},
    { title: 'event 3', date: '2023-04-02' ,id:"7"},
    {
      "title": "har",
      "start": "2023-04-14T15:20:00.000Z",
      id:"8"
    }
  ]
const stylingAndOthersDatas={
  height:700,
  initialView:"dayGridMonth",
  weekends:true,
  hiddenDays:[],
  eventColor:'#537FE7',
  dayHeaders:true,
  dayHeaderFormat:{ weekday: 'short' },
  dayMaxEvents:true,
  dayHeaderClassNames:'text-gray-700 border-gray-300 bg-white py-8 text-center text-xs font-semibold leading-6 text-gray-700'
}
const ParentCalendar =()=>{

  const [parentData, setParentData] = useState(events);
  const [stylingAndOthersData, setStylingAndOthersData] = useState(stylingAndOthersDatas);


  const deleteHandler = (deleteObj)=>{
    console.log("Delete Data",deleteObj);

    
  }
  const addHandler = (addObj)=>{
    console.log("Add Data",addObj);

   
  }

  const editHandler = (editObj)=>{
    console.log("Edit Data",editObj);

   
  }
  console.log(parentData);
 return(
    <Fragment>
        
        <Calendar setDelete={deleteHandler} setAdd={addHandler} setEdit={editHandler} stylingAndOthers={stylingAndOthersData}  events={parentData}></Calendar>
    </Fragment>
 )
}

export default ParentCalendar