import { Dialog, Transition, Listbox, Popover } from "@headlessui/react";
import {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import plusActive from "../../assets/icons/plus_primary.svg";
import arrowDownIcon from "../../assets/icons/arrow-circle-down.svg";
import dotHolderIcon from "../../assets/icons/dot_holder.svg";
import minimize from "../../assets/icons/minimize.svg";
import ZColumnIcon from "./ZColumnIcon";
import TableContext from "../../store/TableContext";

const columnType = [
  {
    id: 1,
    value: "text",
    name: "Text",
  },
  {
    id: 2,
    value: "number",
    name: "Number",
  },
  {
    id: 3,
    value: "select",
    name: "select",
  },
  {
    id: 4,
    value: "multiselect",
    name: "Multi-select",
  },
  {
    id: 5,
    value: "status",
    name: "Status",
  },
  {
    id: 6,
    value: "date",
    name: "Date",
  },
  {
    id: 7,
    value: "files",
    name: "Files",
  },
  {
    id: 8,
    value: "URL",
    name: "URL",
  },
  {
    id: 10,
    value: "email",
    name: "Email",
  },
  {
    id: 11,
    value: "phone",
    name: "Phone",
  },
  {
    id: 12,
    value: "tags",
    name: "Tags",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const solutions = [
  {
    name: "Freeze column",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    name: "Sort ascending",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    name: "Sort decending",
    icon: require("../../assets/icons/arrow_down.svg"),
  },
  {
    name: "Search",
    icon: require("../../assets/icons/search.svg"),
  },
  {
    name: "Filter",
    icon: require("../../assets/icons/filter.svg"),
  },
  {
    name: "Hide in view",
    icon: require("../../assets/icons/hide_view.svg"),
  },
  {
    name: "Edit property",
    icon: require("../../assets/icons/setting.svg"),
  },
];

const dbList = [
  { id: "1", name: "userlist.db" },
  { id: "2", name: "comapny.db" },
  { id: "3", name: "customer.db" },
  { id: "4", name: "master.db" },
  { id: "5", name: "record.db" },
];
const dbColumnList = [
  { id: "1", value: "name", name: "User Name" },
  { id: "2", value: "isActive", name: "Status" },
  { id: "3", value: "email", name: "Email" },
  { id: "4", value: "gender", name: "Gender" },
  { id: "5", value: "company", name: "Company" },
  { id: "6", value: "tags", name: "Tags" },
  { id: "7", value: "balance", name: "Amount" },
  { id: "8", value: "registered", name: "Updated On" },
  { id: "9", value: "age", name: "Age" },
];
const subTypeList = [{ id: "1", name: "Status" }];

const ZColumnConfig = forwardRef((_, ref) => {
  const ctx = useContext(TableContext);
  const [isOpened, setOpened] = useState(false);
  const [isOpeneAddColumn, setOpeneAddColumn] = useState(false);
  const [height, setHeight] = useState("0px");
  const [addColunmHeight, setAddColumnHeight] = useState("0px");
  const [isOpenColumnColor, setOpenColumnColor] = useState(false);
  const [accordionIdx, setAccordionIdx] = useState();
  const contentElement = useRef(null);
  const addColumnElement = useRef(null);
  const [columnForm, setColumnForm] = useState(ctx.headerList);
  const [addColumn, setAddColumn] = useState({
    columnName: "",
    chooseFrom: "",
    chooseColumn: "",
    chooseColumnType: "",
    chooseColumnIcon: "",
    isActive: true,
    subType: "",
  });

  const initiateColorPicker = () => {
    if (ctx.headerList && ctx.headerList.length) {
      const pickerArr = ctx.headerList.map((item) => {
        return { isOpenColumnColor: false };
      });
      return pickerArr;
    } else {
      return [];
    }
  };

  const [colorPcikerHandler, setOpenColumnPicker] =
    useState(initiateColorPicker);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...columnForm];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setColumnForm(copyListItems);
  };

  useImperativeHandle(ref, (category) => ({
    onTriggerColumnRef: onTriggerColumnRef,
  }));

  const onTriggerColumnRef = (category) => {
    if (category == "onClose") ctx.addHeaderList(columnForm);
    // onTriggerColumnData(columnForm);
  };

  const openColorColumnPicker = () => {
    let isToggleColor = !isOpenColumnColor;
    setOpenColumnColor(isToggleColor);
  };

  const onHandleChangeColumnIcon = (index) => (selectedIcon) => {
    const newArray = columnForm.map((item, i) => {
      if (index === i) {
        return { ...item, chooseColumnIcon: selectedIcon };
      } else {
        return item;
      }
    });
    const newColumnColorArr = colorPcikerHandler.map((item, i) => {
      if (index === i) {
        return { ...item, isOpenColorHandler: false };
      } else {
        return item;
      }
    });
    setOpenColumnPicker(newColumnColorArr);
    setColumnForm(newArray);
  };

  const onHandleSelectedIcon = (selectedIcon) => {
    setAddColumn({
      ...addColumn,
      chooseColumnIcon: selectedIcon,
    });
    setOpenColumnColor(false);
  };

  const openColumnColorPicker = (index) => () => {
    const newColumnColorArr = colorPcikerHandler.map((item, i) => {
      if (index === i) {
        return { ...item, isOpenColorHandler: true };
      } else {
        return item;
      }
    });
    setOpenColumnPicker(newColumnColorArr);
  };

  const updateColumn = (index, category) => (e) => {
    const newArray = columnForm.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          [e.target.name]:
            category == "text" ? e.target.value : e.target.checked,
        };
      } else {
        return item;
      }
    });
    setColumnForm(newArray);
  };

  const updateColumnList = (index, fieldName) => (e) => {
    const newArray = columnForm.map((item, i) => {
      if (index === i) {
        return { ...item, [fieldName]: e };
      } else {
        return item;
      }
    });
    setColumnForm(newArray);
  };

  const HandleOpening = (idx) => () => {
    setOpened(!isOpened);
    setAccordionIdx(!isOpened ? idx : null);
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : "0px");
  };

  const handleToggleAddColumn = () => {
    setOpeneAddColumn(!isOpeneAddColumn);
    setAddColumnHeight(
      !isOpeneAddColumn ? `${addColumnElement.current.scrollHeight}px` : "0px"
    );
  };

  const onAddColunmHandler = (event) => {
    event.preventDefault();
    setColumnForm((prevVal) => [...prevVal, addColumn]);
    setOpenColumnPicker((prevVal) => [
      ...prevVal,
      { isOpenColorHandler: false },
    ]);
    setAddColumn({
      columnName: "",
      chooseFrom: "",
      chooseColumn: "",
      chooseColumnType: "",
      chooseColumnIcon: "",
      isActive: true,
      subType: "",
    });
    HandleOpening();
  };

  useEffect(() => {
    localStorage.setItem("columnConfig", JSON.stringify(columnForm));
  }, [columnForm]);

  return (
    <div className="p-6 divide-y divide-gray-200">
      {columnForm &&
        columnForm.map((column, index) => {
          return (
            <div
              key={`colun--list${index}`}
              className="grid grid-cols-1  gap-x-4 py-6 sm:grid-cols-6"
            >
              <div
                className=" sm:col-span-6 flex items-center justify-between"
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                draggable
              >
                <Dialog.Title className="text-lg flex font-medium text-gray-900">
                  <img
                    src={dotHolderIcon}
                    className="mr-3 cursor-move"
                    alt=""
                  />
                  {column.columnName}
                </Dialog.Title>
                <div
                  className="ml-4 flex items-center cursor-pointer"
                  onClick={HandleOpening(index)}
                >
                  {isOpened && accordionIdx == index ? (
                    <img src={minimize} alt="" />
                  ) : (
                    <img src={arrowDownIcon} alt="" />
                  )}
                </div>
              </div>
              <div
                className={`sm:col-span-6  gap-y-6 grid transition-all duration-150 ${
                  isOpened && accordionIdx == index
                    ? "visible  px-4 py-4 "
                    : "invisible"
                }`}
                ref={contentElement}
                style={{ height: accordionIdx == index ? height : "0px" }}
              >
                <div className={`sm:col-span-6`}>
                  <label
                    htmlFor="first-name"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Column Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="columnName"
                      value={column.columnName}
                      onChange={updateColumn(index, "text")}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <Listbox
                    value={column.chooseFrom}
                    name="chooseFrom"
                    onChange={updateColumnList(index, "chooseFrom")}
                  >
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Column Form {column.chooseFrom}
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center h-4">
                          <span className="ml-3 block truncate">
                            {column.chooseFrom}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {dbList &&
                            dbList.map((db) => (
                              <Listbox.Option
                                key={db.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={db.value}
                              >
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      column.chooseFrom == db.value
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {db.name}
                                  </span>
                                  {column.chooseFrom == db.value ? (
                                    <span
                                      className={classNames(
                                        "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="sm:col-span-6">
                  <Listbox
                    value={column.chooseColumn}
                    name="chooseColumn"
                    onChange={updateColumnList(index, "chooseColumn")}
                  >
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Choose Column
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center h-4">
                          <span className="ml-3 block truncate">
                            {column.chooseColumn}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {dbColumnList &&
                            dbColumnList.map((db) => (
                              <Listbox.Option
                                key={db.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={db.value}
                              >
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      column.chooseColumn == db.value
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {db.name}
                                  </span>
                                  {column.chooseColumn == db.value ? (
                                    <span
                                      className={classNames(
                                        "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="sm:col-span-1 mr-3">
                  <label
                    htmlFor="first-name"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Icons
                  </label>
                  <Popover className="relative mt-2 w-11 h-10">
                    <Popover.Button>
                      <span
                        onClick={openColumnColorPicker(index)}
                        className="relative rounded-md w-full -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                      >
                        {column && column.chooseColumnIcon != "" ? (
                          <img src={column.chooseColumnIcon} alt="" />
                        ) : (
                          <span>Aa</span>
                        )}
                      </span>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      show={colorPcikerHandler[index].isOpenColorHandler}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 mt-3 w-72 px-2 sm:px-0">
                        <ZColumnIcon
                          IconList={solutions}
                          existingIcon={column.chooseColumnIcon}
                          triggerSelectedIcon={onHandleChangeColumnIcon(index)}
                        />
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
                <div className="sm:col-span-5">
                  <Listbox
                    value={column.chooseColumnType}
                    name="chooseColumnType"
                    onChange={updateColumnList(index, "chooseColumnType")}
                  >
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Choose Column Type
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center h-4">
                          <span className="ml-3 block truncate">
                            {column.chooseColumnType}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {columnType &&
                            columnType.map((type) => (
                              <Listbox.Option
                                key={type.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={type.value}
                              >
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      column.chooseColumnType == type.value
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {type.name}
                                  </span>
                                  {column.chooseColumnType == type.value ? (
                                    <span
                                      className={classNames(
                                        "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="sm:col-span-6">
                  <Listbox
                    value={column.subType}
                    name="subType"
                    onChange={updateColumnList(index, "subType")}
                  >
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      Sub Type
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="flex items-center h-4">
                          <span className="ml-3 block truncate">
                            {column.subType}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {subTypeList &&
                            subTypeList.map((subType) => (
                              <Listbox.Option
                                key={subType.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={subType.name}
                              >
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      column.subType == subType.name
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {subType.name}
                                  </span>
                                  {column.subType == subType.name ? (
                                    <span
                                      className={classNames(
                                        "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="sm:col-span-6">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        name="isActive"
                        type="checkbox"
                        checked={column.isActive}
                        onChange={updateColumn(index, "checkbox")}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-700"
                      >
                        Active / Show Column
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className={`grid grid-cols-1 gap-y-6 gap-x-4 py-6 sm:grid-cols-6`}>
        <div
          className=" sm:col-span-6 flex items-center justify-between"
          onClick={handleToggleAddColumn}
        >
          <span className="cursor-pointer flex text-sm font-bold text-primary">
            <img src={plusActive} className="mr-2" alt="" />
            Add New Column
          </span>
          <div className="ml-4 flex items-center">
            {isOpened ? (
              <img src={minimize} alt="" />
            ) : (
              <img src={arrowDownIcon} alt="" />
            )}
          </div>
        </div>
        <div
          className={`sm:col-span-6 px-4 grid gap-y-6 ${
            isOpeneAddColumn ? "visible" : "invisible"
          }`}
          ref={addColumnElement}
          style={{ height: addColunmHeight }}
        >
          <div className="sm:col-span-6">
            <label
              htmlFor="first-name"
              className="block text-xs font-bold text-gray-700"
            >
              Column Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="columnName"
                value={addColumn.columnName}
                onChange={(e) =>
                  setAddColumn({ ...addColumn, columnName: e.target.value })
                }
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="sm:col-span-6">
            <Listbox
              value={addColumn.chooseFrom}
              onChange={(e) => setAddColumn({ ...addColumn, chooseFrom: e })}
            >
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                Column Form
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="flex items-center h-4">
                    <span className="ml-3 block truncate">
                      {addColumn.chooseFrom}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {dbList &&
                      dbList.map((db) => (
                        <Listbox.Option
                          key={db.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={db.name}
                        >
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                addColumn.chooseFrom == db.name
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {db.name}
                            </span>
                            {addColumn.chooseFrom == db.name ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="sm:col-span-6">
            <Listbox
              value={addColumn.chooseColumn}
              onChange={(e) => setAddColumn({ ...addColumn, chooseColumn: e })}
            >
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                Choose Column
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="flex items-center h-4">
                    <span className="ml-3 block truncate">
                      {addColumn.chooseColumn}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {dbColumnList &&
                      dbColumnList.map((db) => (
                        <Listbox.Option
                          key={db.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={db.value}
                        >
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                addColumn.chooseColumn == db.value
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {db.name}
                            </span>
                            {addColumn.chooseColumn == db.value ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="city"
              className="block text-xs font-bold text-gray-700 mb-1"
            >
              Icon
            </label>
            <Popover className="relative w-11 h-10">
              <Popover.Button>
                <span
                  onClick={openColorColumnPicker}
                  className="relative rounded-md w-full -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  {addColumn && addColumn.chooseColumnIcon != "" ? (
                    <img src={addColumn.chooseColumnIcon} alt="" />
                  ) : (
                    <span>Aa</span>
                  )}
                </span>
              </Popover.Button>
              <Transition
                as={Fragment}
                show={isOpenColumnColor}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 mt-3 w-72 px-2 sm:px-0">
                  <ZColumnIcon
                    IconList={solutions}
                    triggerSelectedIcon={onHandleSelectedIcon}
                  />
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <div className="sm:col-span-5">
            <Listbox
              value={addColumn.chooseColumnType}
              onChange={(e) =>
                setAddColumn({ ...addColumn, chooseColumnType: e })
              }
            >
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                Choose Column Type
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="flex items-center h-4">
                    <span className="ml-3 block truncate">
                      {addColumn.chooseColumnType}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {columnType &&
                      columnType.map((type) => (
                        <Listbox.Option
                          key={type.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={type.value}
                        >
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                addColumn.chooseColumnType == type.value
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {type.name}
                            </span>
                            {addColumn.chooseColumnType == type.value ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="sm:col-span-6">
            <Listbox
              value={addColumn.subType}
              onChange={(e) => setAddColumn({ ...addColumn, subType: e })}
            >
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                Sub Type
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="flex items-center h-4">
                    <span className="ml-3 block truncate">
                      {addColumn.subType}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {subTypeList &&
                      subTypeList.map((subType) => (
                        <Listbox.Option
                          key={subType.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={subType.name}
                        >
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                addColumn.subType == subType.name
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {subType.name}
                            </span>
                            {addColumn.subType == subType.name ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="sm:col-span-6">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  name="isAdvancedFilter"
                  type="checkbox"
                  checked={addColumn.isActive}
                  onChange={(e) =>
                    setAddColumn({ ...addColumn, isActive: e.target.checked })
                  }
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  Active / Show Column
                </label>
              </div>
            </div>
          </div>
          <div className="sm:col-span-6">
            <button
              onClick={onAddColunmHandler}
              type="button"
              className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
            >
              Add Column
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ZColumnConfig;
