import { Fragment } from "react";
import "./App.css";
import ZMap from "./components/Map/ZMap";
import ZTable from "./components/Table/ZTable";
import ParentGanttChart from "./components/GanttChart/ParentGanttChart";
import ParentCalendar from "./components/Calendar/ParentCalendar";
import ZButton  from "./components/Button/ZButton";
import ZCircularButton from "./components/Button/ZCircularButton"
import ZComponents from "./components/ZComponents/ZComponents"
function App() {
  const people = [
    {
      id: 1,
      full_name: "Wendall Gripton",
      email: "wg@creative.org",
      gender: "Male1",
      age: 100,
      start_date: "2022-01-26",
      dep: "CSE",
    },
    {
      id: 2,
      full_name: "samuel Mollitt",
      email: "smol@github.io",
      gender: "Male1",
      age: 60,
      start_date: null,
      dep: "CSE",
    },
    {
      id: 3,
      full_name: "Ole Rogge",
      email: "orog@usatoday.com",
      gender: "Male1",
      age: 50,
      start_date: "2021-06-23",
      dep: "CSE",
    },
    {
      id: 4,
      full_name: "Elwin Huyge",
      email: null,
      gender: "Male1",
      age: 34,
      start_date: "2021-06-07",
    },
    {
      id: 5,
      full_name: "Anthe Maybery",
      email: "amay@dyndns.org",
      gender: "Female",
      age: 31,
      start_date: "2021-07-05",
      dep: "CSE",
    },
    {
      id: 6,
      full_name: "Arny Amerighi",
      email: "aameri@ibm.com",
      gender: "Male1",
      age: 35,
      start_date: "2021-03-09",
      dep: "CSE",
    },
    {
      id: 7,
      full_name: "Lynnell Shimmans",
      email: "lshimmans6@addthis.com",
      gender: "Male1",
      age: null,
      start_date: "2021-06-03",
      dep: "CSE",
    },
    {
      id: 8,
      full_name: "Pierre Klug",
      email: "pklug7@virginia.edu",
      gender: "Female",
      age: 36,
      start_date: "2021-09-19",
      dep: "CSE",
    },
    {
      id: 9,
      full_name: "Melantha Jakeway",
      email: "mjakeway8@noaa.gov",
      gender: "Female",
      age: 48,
      start_date: "2021-01-07",
      dep: "CSE",
    },
    {
      id: 10,
      full_name: "Jodi Nickless",
      email: "jnickless9@dagon.com",
      gender: "Male1",
      age: 36,
      start_date: "2021-02-05",
      dep: "CSE",
    },
    {
      id: 11,
      full_name: "Wendall Gripton",
      email: "wg@creative.org",
      gender: "Male1",
      age: 100,
      start_date: "2022-01-26",
      dep: "CSE",
    },
    {
      id: 12,
      full_name: "samuel Mollitt",
      email: "smol@github.io",
      gender: "Male1",
      age: 60,
      start_date: null,
      dep: "CSE",
    },
    {
      id: 13,
      full_name: "Ole Rogge",
      email: "orog@usatoday.com",
      gender: "Male1",
      age: 50,
      start_date: "2021-06-23",
      dep: "CSE",
    },
    {
      id: 14,
      full_name: "Elwin Huyge",
      email: null,
      gender: "Male1",
      age: 34,
      start_date: "2021-06-07",
      dep: "CSE",
    },
    {
      id: 15,
      full_name: "Anthe Maybery",
      email: "amay@dyndns.org",
      gender: "Female",
      age: 31,
      start_date: "2021-07-05",
      dep: "CSE",
    },
    {
      id: 16,
      full_name: "Arny Amerighi",
      email: "aameri@ibm.com",
      gender: "Male1",
      age: 35,
      start_date: "2021-03-09",
      dep: "CSE",
    },
    {
      id: 17,
      full_name: "Lynnell Shimmans",
      email: "lshimmans6@addthis.com",
      gender: "Male1",
      age: null,
      start_date: "2021-06-03",
      dep: "CSE",
    },
    {
      id: 18,
      full_name: "Pierre Klug",
      email: "pklug7@virginia.edu",
      gender: "Female",
      age: 36,
      start_date: "2021-09-19",
    },
    {
      id: 19,
      full_name: "Melantha Jakeway",
      email: "mjakeway8@noaa.gov",
      gender: "Female",
      age: 48,
      start_date: "2021-01-07",
      dep: "CSE",
    },
    {
      id: 20,
      full_name: "Jodi",
      email: "jnickless9@dagon.com",
      gender: "Male1",
      age: 36,
      start_date: "2021-02-05",
      dep: "CSE",
    },
    {
      id: 21,
      full_name: "ddd",
      email: "jnickless9@dagon.com",
      gender: "Male1",
      age: 36,
      start_date: "2021-02-05",
      dep: "CSE",
    },
  ];
  const columns = [
    {
      label: "Full Name",
      accessor: "full_name",
      type: "text",
      sortable: true,
      isFreeze: true,
    },
    { label: "Email", accessor: "email", sortable: true, isFreeze: false },
    {
      label: "Gender",
      accessor: "gender",
      type: "chips",
      sortable: true,
      isFreeze: false,
      sortbyOrder: "desc",
    },
    { label: "Age", accessor: "age", sortable: true, isFreeze: true },
    {
      label: "Start date",
      accessor: "start_date",
      type: "date",
      sortable: true,
      isFreeze: false,
    },
    {
      label: "Department",
      accessor: "dep",
      type: "status",
      sortable: true,
      isFreeze: false,
    },
  ];
  ////map points

  const points = {
    //type it will be deside the marker design (1.type:'circle' -it wil be show a circle marker.| 2.type:'pin' -it wil be show a location pin  marker)
    type: "circle", //|circle | pin
  // varient define the map category  (1.'standAlone'-It contains only one prop and shows a location with a description.| 2.'X-points'-It contains multiple props, so it shows multiple locations with a description.|3.'distance'-It has two props and displays the distance between them. )
    varient: "standAlone",  //standAlone | X-points|distance
    content: [
      {
        id: 1,
        title: "Description1", //Give about location description
        lat: 13.067439, // lat value
        lng: 80.237617, // lng value
      },
      {
        id: 2,
        title: "Description2",
        lat: 10.067439,
        lng: 80.237617,
      },
      {
        id: 3,
        title: "Description",
        lat: 10.067439,
        lng: 75.237617,
      },
      {
        id: 4,
        title: "Description",
        lat: 11.067439,
        lng: 72.237617,
      },
      {
        id: 5,
        title: "Description",
        lat: 13.067439,
        lng: 69.237617,
      },
    ],
  };

  /////button props

  const btnprops= {
    text: {
        type: String,
        default: 'button text'
    },
    size: {
        type: String,
        default: 'md'  //xs | sm | md | lg | xl
    },
    varient: {
        type: String,
        default: 'primary' // plain | primary | secondary
    },
    icon: {
        type: String,
        default: 'EnvelopeIcon'
    },
    iconPlacement: {
        type: String,
        default: 'prefix' //prefix | suffix  
    },
    isRoundButton: {
        type: Boolean,
        default: true 
    }
}
////circular btn props
const circularProps= {
  size: {
      type: String,
      default: 'md'  //xs | sm | md | lg | xl
  },
  varient: {
      type: String,
      default: 'secondary' // plain | primary | secondary
  },
  icon: {
      type: String,
      default: 'EnvelopeIcon'
  }
}

  return (
    <Fragment>
        <ZComponents/>
      {/* <ZTable /> */}
    {/* <ParentGanttChart /> */}
      {/* <ZMap /> */}
      {/* <ParentCalendar></ParentCalendar> */}
      {/* <ZGanttChart /> */}
      {/* <ZMap points={points} /> */}
     {/* <ZButton props={btnprops}/>
     <ZCircularButton circleProps={circularProps}/> */}
      {/* <ZMainmap/> */}
    
    </Fragment>
  );
}

export default App;
