import search from "../../assets/icons/search.svg";
import setting from "../../assets/icons/setting.svg";
import addWhite from "../../assets/icons/add_white.svg";
import { useState, Fragment, useRef, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import ZConfiguration from "./ZConfiguration";
import advanceFilter from "../../assets/icons/filterAdvanced.svg";
import exportIcon from "../../assets/icons/export.svg";
import RowConfiguration from "./ZRowConfiguration";
import ZAdvancedFilter from "./ZAdvancedFilter";
import { useContext } from "react";
import TableContext from "../../store/TableContext";
var XLSX = require("xlsx");

const tabs = [
  { name: "General", href: "#profile", current: true },
  { name: "Column", href: "#", current: false },
  { name: "Child", href: "#", current: false },
  { name: "Pivot", href: "#", current: false },
  { name: "Permissions", href: "#", current: false },
];
const team = [
  {
    name: "Leslie Alexander",
    handle: "lesliealexander",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  // More people...
];

function ZTableActions(props) {
  const ctx = useContext(TableContext);
  const [open, setOpen] = useState(false);
  const rowConfigElement = useRef();
  const [rawTableData, setRawTableData] = useState(props.tableData);
  const [commonActionConfig, setCommonConfig] = useState(props.commonConfig);
  const [isShowFilter, setShowFilter] = useState(false);

  const openRowConfig = () => {
    rowConfigElement.current.openConfigDialog();
  };

 

  const onToggleFilter = () => {
    ctx.isToggleFilter(!ctx.isShowFilter);
  };

  const searchItems = (e) => {
    var searchInput = e.target.value;
    // setSearchInput(e.target.value);
    if (searchInput !== "") {
      const filteredData = props.tableData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      props.onFilterDataEmit(filteredData);
    } else {
      props.onFilterDataEmit(rawTableData);
    }
  };

  useEffect(() => {
    setCommonConfig(props.commonConfig);
  }, [rawTableData, props.commonConfig]);

  const exportToExcel = (type, fn, dl) => () => {
    var elt = document.getElementById("tbl_exporttable_to_xls");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(
          wb,
          fn || `${commonActionConfig.tableName}.` + (type || "xlsx")
        );
  };
  return (
    <div className="sm:flex sm:flex-col mt-4">
      <div className="sm:flex-auto">
        {commonActionConfig && commonActionConfig.tableName ? (
          <h1
            className="text-2xl text-black font-bold"
            style={{
              fontSize: commonActionConfig.fontSize,
              fontStyle: commonActionConfig.fontStyle,
              textDecoration: commonActionConfig.fontStyle,
              color: commonActionConfig.color,
            }}
          >
            {commonActionConfig.tableName ? commonActionConfig.tableName : ""}
          </h1>
        ) : (
          <></>
        )}
        {commonActionConfig && commonActionConfig.description ? (
          <p
            className="mt-2 text-base font-normal text-black"
            style={{
              fontSize: commonActionConfig.fontSize,
              fontStyle: commonActionConfig.fontStyle,
              color: commonActionConfig.color,
              textDecoration: commonActionConfig.fontStyle,
            }}
          >
            {commonActionConfig.description}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="sm:flex sm:items-center mt-6 justify-between">
        {commonActionConfig && commonActionConfig.isSearch ? (
          <div className="relative mt-1 rounded-md shadow-sm w-8/12">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <img src={search} alt="" />
            </div>
            <input
              type="text"
              name="searchInput"
              id="searchInput"
              onChange={searchItems}
              style={{
                fontSize: commonActionConfig.fontSize,
                fontStyle: commonActionConfig.fontStyle,
                textDecoration: commonActionConfig.fontStyle,
                color: commonActionConfig.color,
              }}
              className="block w-full rounded-md border-gray-300 pl-10 focus:border-primary focus:primary sm:text-sm"
              placeholder="Search..."
            />
          </div>
        ) : (
          <></>
        )}
        {commonActionConfig && commonActionConfig.isAdvancedFilter ? (
          <button
            type="button"
            style={{
              fontSize: commonActionConfig.fontSize,
              fontStyle: commonActionConfig.fontStyle,
              textDecoration: commonActionConfig.fontStyle,
              color: commonActionConfig.color,
            }}
            onClick={onToggleFilter}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
          >
            <img src={advanceFilter} className="mr-2" alt="" />
            Filter
          </button>
        ) : (
          <></>
        )}
        {commonActionConfig && commonActionConfig.isExport ? (
          <button
            type="button"
            style={{
              fontSize: commonActionConfig.fontSize,
              fontStyle: commonActionConfig.fontStyle,
              textDecoration: commonActionConfig.fontStyle,
              color: commonActionConfig.color,
            }}
            onClick={exportToExcel("xlsx")}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
          >
            <img src={exportIcon} className="mr-2" alt="" />
            Export
          </button>
        ) : (
          <></>
        )}
        <button
          type="button"
          // disabled={commonActionConfig && !commonActionConfig.isEditPermission}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
          onClick={() => setOpen(true)}
        >
          <img src={setting} alt="" />
        </button>
        {commonActionConfig && commonActionConfig.isAddNew ? (
          <button
            type="button"
            style={{
              fontSize: commonActionConfig.fontSize,
              fontStyle: commonActionConfig.fontStyle,
              textDecoration: commonActionConfig.fontStyle
            }}
            onClick={openRowConfig}
            className="inline-flex items-center rounded-md  bg-primary px-4 py-2 text-sm font-medium text-white"
          >
            <img src={addWhite} className="mr-3" alt="" />
            Create New
          </button>
        ) : (
          <></>
        )}
      </div>
      {ctx.isShowFilter && (
        <div>
          <ZAdvancedFilter />
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <ZConfiguration
                      tabs={tabs}
                      team={team}
                      setOpen={(bool) => setOpen(bool)}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <RowConfiguration ref={rowConfigElement} />
    </div>
  );
}

export default ZTableActions;
