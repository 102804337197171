import dragGroupIcon from "../../assets/icons/dot_holder.svg";
import ColumnResizer from "react-table-column-resizer";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import TableContext from "../../store/TableContext";
import { Popover, Transition } from "@headlessui/react";
import chevDowIcon from "../../assets/icons/chevron-down.svg";
import ZTable from "./ZTable";
import ZChildWidget from "./ZChildWidget";

const chipsColor = [
  {
    id: 20,
    color: "#B9E6FE",
  },
  {
    id: 21,
    color: "#C7D7FE",
  },
  {
    id: 22,
    color: "#D9D6FE",
  },
  {
    id: 23,
    color: "#E9D7FE",
  },
  {
    id: 24,
    color: "#D5D9EB",
  },
  {
    id: 25,
    color: "#FCCEEE",
  },
  {
    id: 26,
    color: "#FECDD6",
  },
  {
    id: 27,
    color: "#FDDCAB",
  },
  {
    id: 28,
    color: "#6CE9A6",
  },
  {
    id: 29,
    color: "#7CD4FD",
  },
  {
    id: 30,
    color: "#A4BCFD",
  },
];

const commonCountOptions = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Count all",
  },
  {
    id: 3,
    name: "Count values",
  },
  {
    id: 4,
    name: "Count unique values",
  },
  {
    id: 5,
    name: "Count empty",
  },
  {
    id: 6,
    name: "Count not empty",
  },
  {
    id: 7,
    name: "Percent empty",
  },
  {
    id: 8,
    name: "Percent not empty",
  },
];

const numberCountOptions = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Count all",
  },
  {
    id: 3,
    name: "Count values",
  },
  {
    id: 4,
    name: "Count unique values",
  },
  {
    id: 5,
    name: "Count empty",
  },
  {
    id: 6,
    name: "Count not empty",
  },
  {
    id: 7,
    name: "Percent empty",
  },
  {
    id: 8,
    name: "Percent not empty",
  },
  {
    id: 9,
    name: "Sum",
  },
  {
    id: 10,
    name: "Average",
  },
  {
    id: 11,
    name: "Median",
  },
  {
    id: 12,
    name: "Min",
  },
  {
    id: 13,
    name: "Max",
  },
  {
    id: 14,
    name: "Range",
  },
];

const dateCountOptions = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Count all",
  },
  {
    id: 3,
    name: "Count values",
  },
  {
    id: 4,
    name: "Count unique values",
  },
  {
    id: 5,
    name: "Count empty",
  },
  {
    id: 6,
    name: "Count not empty",
  },
  {
    id: 7,
    name: "Percent empty",
  },
  {
    id: 8,
    name: "Percent not empty",
  },
  {
    id: 9,
    name: "Earliest date",
  },
  {
    id: 10,
    name: "Latest date",
  },
  {
    id: 11,
    name: "Date range",
  },
];

const checkboxCountOptions = [
  {
    id: 1,
    name: "None",
  },
  {
    id: 2,
    name: "Count all",
  },
  {
    id: 3,
    name: "Checked",
  },
  {
    id: 4,
    name: "Unchecked",
  },
  {
    id: 5,
    name: "Percent checked",
  },
  {
    id: 5,
    name: "Percent unchecked",
  },
];

const childWidget = [
  { name: "Work Details", href: "#", current: true },
  { name: "Profile", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ZTableRow(props) {
  const [dataRow, setDataRow] = useState(props.tableData);
  const commonConfig = props.commonConfig;
  const [currentTab, setCurrentTab] = useState(0);
  const [varient, setVarient] = useState(props.varient);
  const [isShowChildWidget, setShowChildWidget] = useState(true);
  // const dragOver = props.dragOver;
  const [column, setColumn] = useState(props.columns);
  const ctx = useContext(TableContext);
  useEffect(() => {
    setColumn(ctx.headerList);
    setDataRow(props.tableData);
    formatData()
  }, [ctx, props.tableData]);

  const dragItem = useRef();
  const dragOverItem = useRef();
  const formatData = () => {
    setColumn((latest) => {

      const data = latest.map((data) => {
        const arr = []
        switch (data?.dataFormat?.condition?.name) {
          case "Text is exactly":
            dataRow.forEach((element1, index) => {
              data?.dataFormat?.columns?.forEach(element2 => {
                if (element1?.[data.chooseColumn] == element2) {
                  arr.push(index)
                }

              });
            });
            return { ...data, "output": arr }

          case "Text in":
            dataRow.forEach((element1, index) => {
              data?.dataFormat?.columns?.forEach(element2 => {
                if (element1?.[data.chooseColumn]?.toLocaleLowerCase().includes(element2?.toLocaleLowerCase())) {
                  arr.push(index)
                }

              });
            });
            return { ...data, "output": arr }
          case "Text starts with":
            dataRow.forEach((element1, index) => {
              data?.dataFormat?.columns?.forEach(element2 => {
                if (element1?.[data.chooseColumn]?.toLocaleLowerCase().startsWith(element2?.toLocaleLowerCase())) {
                  arr.push(index)
                }

              });
            });
            return { ...data, "output": arr }
          default:
            return data
        }

      })
      return data;
    })
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...dataRow];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setDataRow(copyListItems);
  };

  const ZDateFormat = (date) => {
    let formattedDate = new Intl.DateTimeFormat({
      dateStyle: "medium",
    }).format();
    return formattedDate;
  };

  const OnGetFirstCharecter = ({ word }) => {
    let splitWord = word.match(/\b(\w)/g);
    let splitCharecter = splitWord ? splitWord.join("") : "";
    return (
      <span
        style={{ backgroundColor: commonConfig.color }}
        className="opacity-30 min-w-[40px] inline-flex mr-3 h-10 w-10 items-center justify-center rounded-full"
      >
        <span className="font-medium leading-none opacity-100 text-white">
          {splitCharecter}
        </span>
      </span>
    );
  };

  const onHandleChildWidget = (idx) => {
    let selectedItem = dataRow[idx];
    if (selectedItem && selectedItem.isChildWidget) {
      setShowChildWidget(!isShowChildWidget);
    }
  };

  return (
    <tbody
      style={{
        backgroundColor: commonConfig.columnBG,
      }}
    >
      {dataRow.map((data, indexP) => {
        return (
          <>
            <tr
              key={`data-row${indexP}`}
              // style={{
              //   backgroundColor:
              //     index % 2 === 0 && commonConfig.isRowAlternation
              //       ? commonConfig.columnBG
              //       : "",
              //     opacity :  index % 2 === 0 && commonConfig.isRowAlternation ? '50%' : ''
              // }}
              className={`h-[4.5rem] group hover:bg-gray-50 transition ease-in-out delay-150 ${indexP % 2 === 0 && commonConfig.isRowAlternation
                ? "bg-lightRed"
                : ""
                } `}
              onDragStart={(e) => dragStart(e, indexP)}
              onDragEnter={(e) => dragEnter(e, indexP)}
              onDragEnd={drop}
              draggable
            >
              {column.map(
                ({ chooseColumn, chooseColumnType, isActive, output, dataFormat }, index) => {
                  const tData = data[chooseColumn] ? data[chooseColumn] : "";
                  return (
                    <>
                      {isActive ? (
                        <>
                          <td
                            onClick={(index) => onHandleChildWidget(index)}
                            key={`td-data${chooseColumn}`}
                            style={{
                              color: output?.length > 0 ? output?.includes(indexP) ? dataFormat?.colour : commonConfig.rowColor : commonConfig.rowColor,
                              fontSize: output?.length > 0 ? output?.includes(indexP) ? dataFormat?.fontSize : commonConfig.rowFontSize : commonConfig.rowFontSize,
                              fontStyle: output?.length > 0 ? output?.includes(indexP) ? dataFormat?.fontStyle : commonConfig.rowFontStyle : commonConfig.rowFontStyle,
                              textDecoration: output?.length > 0 ? output?.includes(indexP) ? dataFormat?.fontStyle : commonConfig.rowFontStyle : commonConfig.rowFontStyle,
                              backgroundColor: output?.length > 0 ? output?.includes(indexP) ? dataFormat?.cellBGColour : commonConfig.columnBG
                                : commonConfig.columnBG

                            }}
                            className={`border-b min-w-[200px] border-gray-200  py-4 pl-4 pr-3 text-sm font-medium text-gray-900`}
                          >
                            <div className="flex items-center  text-ellipsis overflow-hidden whitespace-nowrap ">
                              {index == 0 ? (
                                <img
                                  src={dragGroupIcon}
                                  className="mr-3 cursor-move focus-visible:outline-none invisible group-hover:visible group-focus:visible"
                                  alt=""
                                />
                              ) : (
                                <></>
                              )}
                              {/* {icon ? <img className="mr-2" src={icon} alt="" /> : <></>} */}
                              {chooseColumnType &&
                                chooseColumnType == "text" ? (
                                <>
                                  {tData != "" && index === 0 ? (
                                    <OnGetFirstCharecter word={tData} />
                                  ) : (
                                    <img
                                      className="inline-block h-10 w-10 mr-2 rounded-full"
                                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                      alt=""
                                    />
                                  )}
                                  {tData}
                                </>
                              ) : chooseColumnType == "status" ? (
                                <>
                                  {tData ? (
                                    <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                                      <svg
                                        className="-ml-0.5 mr-1.5 h-2 w-2 text-green-800"
                                        fill="currentColor"
                                        viewBox="0 0 8 8"
                                      >
                                        <circle cx={4} cy={4} r={3} />
                                      </svg>
                                      active
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
                                      <svg
                                        className="-ml-0.5 mr-1.5 h-2 w-2 text-red-800"
                                        fill="currentColor"
                                        viewBox="0 0 8 8"
                                      >
                                        <circle cx={4} cy={4} r={3} />
                                      </svg>
                                      In-active
                                    </span>
                                  )}
                                </>
                              ) : chooseColumnType == "tags" ? (
                                <div className="flex justify-between">
                                  {tData && tData.length >= 4 ? (
                                    <div className="flex justify-between">
                                      {tData.slice(0, 4).map((tag, i) => (
                                        <span
                                          key={`tag---${i}`}
                                          style={{
                                            fontSize: commonConfig.rowFontSize,
                                            fontStyle:
                                              commonConfig.rowFontStyle,
                                            backgroundColor:
                                              chipsColor[i].color,
                                          }}
                                          className={`mr-2 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium text-gray-800  `}
                                        >
                                          {tag}
                                        </span>
                                      ))}
                                      <span
                                        className={`bg-gray-100 inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium text-gray-800  `}
                                      >
                                        +{Number(tData.length - 4)}
                                      </span>
                                    </div>
                                  ) : (
                                    tData.map((tag, i) => (
                                      <span
                                        key={`tag---${i}`}
                                        style={{
                                          backgroundColor: chipsColor[i].color,
                                        }}
                                        className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium text-gray-800  `}
                                      >
                                        {tag}
                                      </span>
                                    ))
                                  )}
                                </div>
                              ) : chooseColumnType == "URL" ? (
                                <>
                                  <a
                                    className="text-primary underline"
                                    href={tData}
                                  >
                                    {tData}
                                  </a>
                                </>
                              ) : chooseColumnType == "file" ? (
                                <>
                                  <a className="font-extrabold">{tData}</a>
                                </>
                              ) : chooseColumnType == "checkbox" ? (
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                />
                              ) : chooseColumnType == "email" ? (
                                <a href={`mailto:${tData}`}>{tData}</a>
                              ) : chooseColumnType == "number" ? (
                                <>{tData}</>
                              ) : chooseColumnType == "date" ? (
                                <ZDateFormat date={tData} />
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                          <ColumnResizer
                            style={{ display: "none" }}
                            key={`td-resizer${chooseColumn}`}
                            className="bg-none hover:bg-none"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                }
              )}

            </tr>
            {
              data &&
                data.isChildWidget &&
                isShowChildWidget &&
                varient === "parent"
                ? (
                  <>
                    {data.isActive ? (<tr>
                      <td colSpan={16}>
                        <div>
                          <nav
                            className="-mb-px flex space-x-6"
                            x-descriptions="Child Widget"
                          >
                            {childWidget.map((tab, index) => (
                              <a
                                key={tab.name}
                                onClick={(e) => setCurrentTab(index)}
                                className={classNames(
                                  index == currentTab
                                    ? "border-indigo-500 text-indigo-600"
                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                  "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                                )}
                              >
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                          <ZChildWidget />
                        </div>
                      </td>
                    </tr>
                    ) : null}
                  </>
                ):null}
                </>
                )})}
            <tr>
              {column.map(({ chooseColumn, chooseColumnType, isActive }, index) => {
                var countOptions = [];
                if (chooseColumnType == "date") {
                  countOptions = [...dateCountOptions];
                } else if (chooseColumnType == "number") {
                  countOptions = [...numberCountOptions];
                } else if (chooseColumn == "checkbox") {
                  countOptions = [...checkboxCountOptions];
                } else {
                  countOptions = [...commonCountOptions];
                }
                return (
                  <>
                    {isActive ? (
                      <>
                        <td
                          key={`td-data${chooseColumn}`}
                          className={`w-48 bg-gray-100 whitespace-nowrap border-b border-gray-200  py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 `}
                        >
                          <Popover>
                            {({ isOpenFilter }) => (
                              <>
                                <Popover.Button className="w-full bg-gray-100  focus:outline-none">
                                  <span className="inline-flex items-center rounded-md  px-2.5 py-0.5 text-sm font-medium text-gray-800 cursor-pointer">
                                    Calculate
                                    <img
                                      src={chevDowIcon}
                                      className="w-3 ml-2"
                                      alt=""
                                    />
                                  </span>
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute z-50 w-60 transform">
                                    <div className="relative grid grid-cols-6 px-2 py-2 rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                                      {countOptions.map((item, index) => {
                                        return (
                                          <div
                                            className="sm:col-span-6"
                                            //   onClick={onSelectFilter(item)}
                                            key={`filter--list--${index}}`}
                                          >
                                            <a className="flex rounded-mdv cursor-pointer p-2 transition duration-150 ease-in-out hover:bg-gray-50">
                                              <p className="text-sm text-gray-500">
                                                {item.name}
                                              </p>
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </td>
                        <ColumnResizer
                          style={{ display: "none" }}
                          key={`td-resizer${chooseColumn}`}
                          className="bg-none hover:bg-none"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tr>
          </tbody >
  )
            }

  export default ZTableRow;
