import { Transition, Listbox } from "@headlessui/react";
import { Fragment,useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import plusActive from "../../assets/icons/plus_primary.svg";
import setting from "../../assets/icons/setting.svg";
import moreVertzIcon from "../../assets/icons/more-vertical.svg";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ZChildWidgetConfig = ({ people }) => {
  const [selected, setSelected] = useState(people[2]);

  return (
    <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0 bg-gray-50 ">
      <ul
        role="list"
        className="divide-y divide-gray-200 bg-white px-4 py-5  sm:p-6"
      >
        {people.map((person) => (
          <li key={person.email} className="flex py-4">
            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
            <div className="ml-3 grow">
              <p className="text-sm font-medium text-gray-700">{person.name}</p>
              <p className="text-sm text-gray-500  ">
                Column - Count - Empty - 21
              </p>
            </div>
            <img src={moreVertzIcon} alt="" />
          </li>
        ))}
      </ul>
      <div className="mt-4  px-4 py-5  sm:p-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <span className="cursor-pointer flex text-sm font-bold text-primary">
            <img src={plusActive} className="mr-2" alt="" />
            Add Child Widget
          </span>
        </div>
        <div className="sm:col-span-6">
          <label
            htmlFor="first-name"
            className="block text-xs font-bold text-gray-700"
          >
            Widget Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-5">
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  Choose Child Widget
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span className="ml-3 block truncate">
                        {selected.name}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {people.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    person.online
                                      ? "bg-green-400"
                                      : "bg-gray-200",
                                    "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {person.name}
                                  <span className="sr-only">
                                    {" "}
                                    is {person.online ? "online" : "offline"}
                                  </span>
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="sm:col-span-1 flex items-end">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  "
          >
            <img src={setting} alt="" />
          </button>
        </div>
        <div className="sm:col-span-6">
          <button
            type="button"
            className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
          >
            Add Child Widget
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZChildWidgetConfig;
