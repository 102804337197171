import { createContext } from "react";

const TableContext = createContext({
  headerList: [],
  dataList: [],
  commonConfig: {},
  advancedFilterData: {},
  isShowFilter: Boolean,
  isOpenColumnConfig:Boolean,
  columnProperties:{},
  openColumnConfig:(item) => {},
  addCommonConfig: (item) => {},
  addHeaderList: (item) => {},
  addDataList: (item) => {},
  addAdvancedFilter: (item) => {},
});

export default TableContext;
