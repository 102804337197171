import ZGanttChart from "./ZGanttChart"
const currentDate = new Date();
const ganttDataList = [
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    name: "Some Project",
    id: "ProjectSample",
    progress: 40,
    type: "project",
    hideChildren: true,
    displayOrder: 1,
    isDisabled: false
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    end: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      3,
      12,
      28
    ),
    name: "Idea",
    id: "Task 0",
    progress: 45,
    type: "task",
    project: "ProjectSample",
    displayOrder: 2,
    isDisabled: false,
    fontSize: "10000"
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
    name: "Research",
    id: "Task 1",
    progress: 25,
    dependencies: ["Task 0"],
    type: "task",
    project: "ProjectSample",
    displayOrder: 3,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
    name: "Discussion with team",
    id: "Task 2",
    progress: 10,
    dependencies: ["Task 1"],
    type: "task",
    project: "ProjectSample",
    displayOrder: 4,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
    name: "Developing",
    id: "Task 3",
    progress: 2,
    dependencies: ["Task 2"],
    type: "task",
    project: "ProjectSample",
    displayOrder: 5,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
    name: "Review",
    id: "Task 4",
    type: "task",
    progress: 70,
    dependencies: ["Task 2"],
    project: "ProjectSample",
    displayOrder: 6,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
    name: "Release",
    id: "Task 6",
    progress: currentDate.getMonth(),
    type: "milestone",
    dependencies: ["Task 4"],
    project: "ProjectSample",
    displayOrder: 7,
  },
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
    name: "Party Time",
    id: "Task 9",
    progress: 0,
    isDisabled: false,
    type: "task",
  },
];
const additionalStyling = {
  preStepsCount: 1,
  headerHeight: 50,
  ganttHeight:0,
  todayColor: "#2E8BC0",
  arrowIndent: 50,
  columnWidth:80,
  rowHeight:80,
  barCornerRadius: 5,
  barFill: 50,
  fontSize: 16,
  arrowColor: "red"
}
/*comment here
 
   ganttData = Array of objects
   
  object structure with description :
       start = "Starting date of the task/project/milestone" - type:Date,required
       end =  "End date of the task/project/milestone"  - type:Date,required
       name = "Name of the task/project/milestone"              - type:string,required
       id = "task/project/milestone unique id"                  - type:string,required
       progress = "Percent of the task/project/milestone progress"  - type:number,required
       isDisabled = Disables all action for current task/project/milestone,  -type:boolean
       type = "project/task/milestone" - type:string,required
       dependencies = "Enter the parent dependencies id" - type:Array<string>
       hideChildren = "Hiding children in default view" - type:boolean
       project = "Task project name" - type:string
       

   Array of object structure = [{
       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
       name: "Party Time",
       id: "Task 9",
       progress: 0,
       isDisabled: true,
       type: "task/project/milestone",
       dependencies:["Task11","Task2"],
       hideChildren:true,
       project:"Task project name"
   }]


   additionalStling : object
 
   object structure with description :

   preStepsCount = "Specifies empty space before the first task"
   headerHeight = "Specifies the header height"
   ganttHeight = Specifies the gantt chart height without header. Default is 0. It`s mean no height limitation.
   todayColor = "Specifies the current period column fill color."
   arrowIndent = "Specifies the relationship arrow right indent. Sets in px"
   columnWidth = "Specifies the time period width."
   rowHeight = "Specifies the row height"
   barCornerRadius = "Specifies the border radius of task bar"
   barFill = "Specifies the taskbar occupation. Sets in percent from 0 to 100."
   fontSize = "Specifies the font size"
   arrowColor = "Specifies the arrow color"
 
*/
const ParentGanttChart = () => {

  return (
    <div>
      <ZGanttChart ganttData={ganttDataList} additionalStling={additionalStyling} />
    </div>

  )
}

export default ParentGanttChart