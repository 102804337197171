import { TrashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

function ChipInput(props) {
  console.log(props);
  const [text, setText] = useState("");
  const [chips, setChips] = useState(props.columnValue ? props.columnValue : []);
  const [validationError, setValidationError] = useState("");

  function removeChip(chipToRemove) {
    // filtering out the chip that the user wants to remove
    const updatedChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(updatedChips);
    props.onSelectLanguage(updatedChips);
  }

  function handlePressEnter(e) {
    // don't submit the form if the user presses 'Enter'
    if (e.key === "Enter") e.preventDefault();
    // return if the user pressed a key that is not 'Enter', or the user hasn't typed anything
    if (e.key !== "Enter" || !text) return;
    // need to show error if the user tries to add the same input more than once
    if (chips.includes(text)) {
      return setValidationError("Cannot add the same input more than once");
    }
    // adding the input value to chips array
    const latest = [...chips, e.target.value];


    setChips((prevState) => [...prevState, e.target.value]);
    // clearing the input box
    setText("");
    // clearing error message
    setValidationError("");
    props.onSelectLanguage(latest);
  }

  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-xs font-bold text-gray-700"
      >
        Column Value
      </label>
      <div className="input-container border border-gray-300">
        {chips.map((chip, index) => (
          <span key={index} className="inline-flex items-center rounded-full bg-gray-100 px-2 py-0.5 text-sm font-medium text-gray-800">
            {chip}
            <TrashIcon
              className="h-4 w-6"
              onClick={() => removeChip(chip)}
              tabIndex="0"
            />
          </span>
        ))}
        <input
          type="text"
          name="first-name"
          placeholder="Press Enter to add tag"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handlePressEnter}
          autoComplete="given-name"
          className="block  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      {validationError && <p className="error-message">{validationError}</p>}
    </div>
  );
}

export default ChipInput;
