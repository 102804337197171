import { useState } from "react";
import TableContext from "./TableContext";
import rawData from "../assets/data/list.json";

const fetchData = () => {
  localStorage.setItem("dataList", JSON.stringify(rawData));
};


const headList = localStorage.getItem("columnConfig")
  ? JSON.parse(localStorage.getItem("columnConfig"))
  : [];
const dataList = localStorage.getItem("dataList")
  ? JSON.parse(localStorage.getItem("dataList"))
  : [];
const commonConfig = localStorage.getItem("commonconfig")
  ? JSON.parse(localStorage.getItem("commonconfig"))
  : {};
const advancedFilter = localStorage.getItem("advancedFilter")
  ? JSON.parse(localStorage.getItem("advancedFilter"))
  : {};
if (dataList?.length === 0) {
  fetchData();
}
const TableProvider = (props) => {
  const [tableContextData, setTableContextData] = useState({
    headerList: headList,
    dataList: dataList,
    advancedFilterData: advancedFilter,
    commonConfig: commonConfig,
    isShowFilter: Boolean,
    isOpenColumnConfig: Boolean,
    columnProperties: {},
  });

  const addHeaderHandler = (item) => {
    setTableContextData({
      ...tableContextData,
      headerList: item,
    });
  };

  const addDataHandler = (item) => {
    setTableContextData({
      ...tableContextData,
      dataList: item,
    });
  };

  const onAddCommonConfig = (data) => {
    setTableContextData({
      ...tableContextData,
      headerList: data.headerList,
      commonConfig: data.commonConfig,
    });
  };

  const addAdvancedFilterHandler = (data) => {
    setTableContextData({
      ...tableContextData,
      advancedFilterData: data,
      isShowFilter: true,
    });
  };

  const onToggleFilterHandler = (isToggle) => {
    setTableContextData({
      ...tableContextData,
      isShowFilter: isToggle,
    });
  };

  const openColumnConfigHandler = (data) => {
    setTableContextData({
      ...tableContextData,
      columnProperties: data,
      isOpenColumnConfig: true,
    });
  };

  const tableContext = {
    headerList: tableContextData.headerList,
    dataList: tableContextData.dataList,
    commonConfig: tableContextData.commonConfig,
    advancedFilterData: tableContextData.advancedFilterData,
    isShowFilter: tableContextData.isShowFilter,
    isOpenColumnConfig: tableContextData.isOpenColumnConfig,
    columnProperties: tableContextData.columnProperties,
    openColumnConfig: openColumnConfigHandler,
    isToggleFilter: onToggleFilterHandler,
    addCommonConfig: onAddCommonConfig,
    addHeaderList: addHeaderHandler,
    addDataList: addDataHandler,
    addAdvancedFilter: addAdvancedFilterHandler,
  };
  return (
    <TableContext.Provider value={tableContext}>
      {props.children}
    </TableContext.Provider>
  );
};

export default TableProvider;
