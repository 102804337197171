import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useRef, useState } from "react";
import ZGeneralConfig from "./ZGeneralConfig";
import ZColumnConfig from "./ZColumnConfig";
import ZChildWidgetConfig from "./ZChildWidgetConfig";
import ZPivotConfig from "./ZPivotConfig";
import ZPermissionConfig from "./ZPermissionConfig";
import TableContext from "../../store/TableContext";

const people = [
  { id: 1, name: "Wade Cooper", online: true },
  { id: 2, name: "Arlene Mccoy", online: false },
  { id: 3, name: "Devon Webb", online: false }
];

const permissionList = [
  {
    id: 1,
    name: "Admin",
    isView: true,
    isEdit: false,
  },
  {
    id: 2,
    name: "Manager",
    isView: true,
    isEdit: false,
  },
  {
    id: 3,
    name: "User",
    isView: true,
    isEdit: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ZConfiguration = (props) => {
  const { tabs, team, setOpen } = props;
  const generalRef = useRef(null);
  const columnRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);
  const ctx = useContext(TableContext);

  const onCloseConfigHandler = () => {
    let commonConfig = localStorage.getItem("commonconfig");
    let columnConfig = localStorage.getItem("columnConfig");
    if (commonConfig && columnConfig) {
      ctx.addCommonConfig({
        headerList: JSON.parse(columnConfig),
        commonConfig: JSON.parse(commonConfig),
      });
    }
    setOpen(false);
  };

  return (
    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-3xl font-bold text-gray-900">
            Configuration
          </Dialog.Title>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="rounded-md border p-1 border-gray-300 bg-white text-gray-400 hover:text-gray-500"
              onClick={onCloseConfigHandler}
            >
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-200">
        <div className="px-6">
          <nav className="-mb-px flex space-x-6" x-descriptions="Tab component">
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                onClick={(e) => setCurrentTab(index)}
                className={classNames(
                  index == currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      {currentTab == 0 ? (
        <ZGeneralConfig people={people} ref={generalRef} />
      ) : currentTab == 1 ? (
        <ZColumnConfig ref={columnRef} />
      ) : currentTab == 2 ? (
        <ZChildWidgetConfig people={people} />
      ) : currentTab == 3 ? (
        <ZPivotConfig people={people} />
      ) : (
        <ZPermissionConfig permissionList={permissionList} />
      )}
    </div>
  );
};

export default ZConfiguration;
