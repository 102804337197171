export const ColumnGeneration = (column) => {
  let generationColumn = [];
  if(column && column.length > 0){
    generationColumn = column.map((item) => {
      return {
        ...item,
        ["sortable"]: true,
        ["isFreeze"]: false
      };
    });
  }
  return generationColumn;
};

