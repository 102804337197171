import React from "react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
const ZButton = ({ props }) => {
  const size = "md";
  const varient = "plain";

  const sizes = {
    xs: "px-3 py-1.5  text-xs",
    sm: "px-3.5 py-2  text-sm",
    md: "px-4 py-2  text-sm",
    lg: "px-5 py-2  text-base",
    xl: "px-6 py-3  text-base",
  };
  const varients = {
    primary: "bg-indigo-600 text-white border-transparent",
    secondary: "bg-indigo-100 text-indigo-700 border-transparent",
    plain: "bg-white text-gray-700 border-gray-300",
  };
  const prefixIconPlacement = {
    xs: "-ml-0.5 mr-2 h-4 w-4",
    sm: "-ml-1 mr-2 h-5 w-5",
    md: "-ml-1 mr-3 h-5 w-5",
    lg: "-ml-1 mr-3 h-5 w-5",
    xl: "-ml-1 mr-3 h-5 w-5",
  };
  const suffixIconPlacement = {
    xs: "ml-2 -mr-0.5 h-4 w-4",
    sm: "ml-2 -mr-1 h-5 w-5",
    md: "ml-3 -mr-1 h-5 w-5",
    lg: "ml-3 -mr-1 h-5 w-5",
    xl: "ml-3 -mr-1 h-5 w-5",
  };
  const iconBg = {
    primary: "text-white",
    secondary: "text-indigo-700",
    plain: "text-gray-700",
  };

  return (
    <div>
      <button
        type="button"
        className={`${varients[props.varient.default]} ${
          sizes[props.size.default]
        } ,
    ${props.isRoundButton.default ? "rounded-full" : "rounded-md"}
       ${
         props.varient.default == "plain"
           ? "hover:bg-gray-50"
           : props.varient.default == "primary"
           ? "hover:bg-indigo-700"
           : "hover:bg-indigo-300"
       } inline-flex capitalize items-center border font-medium shadow-sm focus:outline-none`}
      >
        {props.icon.default && props.iconPlacement.default === "prefix" ? (
          <EnvelopeIcon
            className={`${prefixIconPlacement[props.size.default]} ${
              iconBg[props.varient.default]
            } `}
          />
        ) : (
          ""
        )}
        {props.text.default}
        {props.icon.default && props.iconPlacement.default === "suffix" ? (
          <EnvelopeIcon
            className={`${suffixIconPlacement[props.size.default]} ${
              iconBg[props.varient.default]
            } `}
          />
        ) : (
          ""
        )}{" "}
      </button>
    </div>
  );
};
export default ZButton;
