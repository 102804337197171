import { useRef, useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ZPermissionConfig = ({ permissionList }) => {
  const [enabled, setEnabled] = useState();

  return (
    <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0 px-4 py-5  sm:p-6">
      <table className="min-w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-black sm:pl-6 md:pl-0"
            >
              User Group
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-bold text-black sm:table-cell"
            >
              View
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-bold text-black sm:table-cell"
            >
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {permissionList.map((permission) => (
            <tr key={permission.id}>
              <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-black">{permission.name}</div>
              </td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ZPermissionConfig;
