import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import "./GanttChart.css"
import { useState ,useEffect} from "react";

const ZGanttChart = (props) => {
  const [dataList, setDataList] = useState(props.ganttData)
  const [stylingData, setStylingData] = useState(props.additionalStling)
  


  
  // useEffect(() => {
  //   setDataList((dataList)=>{
  //     const data=dataList.map((element,index) => {
  //       dataList[0]['progress']=50
  //     });
  //     console.log(data);
  //     return data
  //   })
  // }, [])
  
  const onTaskChange = (task) => {
    let newTasks = dataList.map(t => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEnd(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        
          newTasks = newTasks.map(t =>
            t.id === task.project ? changedProject : t
          );
        
       
      }
    }
    setDataList(newTasks)
    
    console.log("task", task);
  };
  const changeRootPercent = (e) => {
    let newData = dataList.map(t => (t.id === e.id ? e : t))
    if (e.project) {
      const percent=getPercent(newData, e.project)
      const project = newData[newData.findIndex(t => t.id === e.project)];
      const changedProject = { ...project, "progress":percent };
      newData = newData.map(t =>
        t.id === e.project ? changedProject : t
      );
    }
    setDataList(newData)
  };
  const getPercent = (tasks,projectId)=>{
    let totalPercent=0
    let finalPercent
    const projectTasks = tasks.filter(t => ((t.project === projectId) && (t.type === 'task')));
    projectTasks.forEach(element => {
      totalPercent += element.progress
    });
    finalPercent = totalPercent / projectTasks.length
    return finalPercent
    
  }
  const onTaskDelete = (e) => {
    console.log("onTaskDelete", e);
  };
  const onProgressChange = (e) => {
    setDataList((dataList) => {
      const newData = dataList.map(t => (t.id === e.id ? e : t))
      return newData
    });
    changeRootPercent(e)
    console.log("onProgressChange", e);
  };
  const onDblClick = (e) => {
    console.log("onDblClick", e);
  };
  const onClick = (e) => {
    console.log("onClick", e);
  };
  const onSelect = (e, check) => {
    console.log("onSelect", e, check);
  };

  const handleExpanderClick = (e) => {
    console.log("expand", e);
   
    setDataList((dataList) => {
      
      const newData = dataList.map(t => (t.id === e.id ? e : t))
      return newData
    });
    

  };
  const getStartEnd = (tasks, projectId) => {
   
    const projectTasks = tasks.filter(t => t.project === projectId);
  
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }
  return (
    <Gantt
      tasks={dataList}
      onDateChange={onTaskChange}
      onExpanderClick={handleExpanderClick}
      onTaskDelete={onTaskDelete}
      onProgressChange={onProgressChange}
      onDoubleClick={onDblClick}
      onClick={onClick}
      onSelect={onSelect}
      viewMode={ViewMode.Day}
      preStepsCount={stylingData.preStepsCount}
      headerHeight={stylingData.headerHeight}
      ganttHeight={stylingData.ganttHeight}
      todayColor={stylingData.todayColor}
      arrowIndent={stylingData.arrowIndent}
      columnWidth={stylingData.columnWidth}
      rowHeight={stylingData.rowHeight}
      barCornerRadius={stylingData.barCornerRadius}
      barFill={stylingData.barFill}
      fontSize={stylingData.fontSize}
      arrowColor={stylingData.arrowColor}

    />
  );
};

export default ZGanttChart;
