import { useEffect, useState, useContext } from "react";
import ZTableActions from "./ZTableActions";
import ZTableHeader from "./ZTableHeader";
import ZTablePagination from "./ZTablePagination";
import ZTableRow from "./ZTableRow";
import { HandleSorting } from "./ZTableSort";
import ZTableWrapper from "./ZTableWrapper";
import "./table.css";
import TableContext from "../../store/TableContext";
import { ColumnGeneration } from "../../service/ZTableUtils";
import ZColumnProperties from "./ZColumnProperties";

let pageLength = [10, 20, 30, 50, 75];
let autoPageMinList = ["05", "10", "15", "30"];

function ZTable() {
  const tableDataCtx = useContext(TableContext);
  const [data, setTableRawdata] = useState(tableDataCtx.dataList);
  const [commonConfig, setCommonConfig] = useState(tableDataCtx.commonConfig);
  const [columns, setColumn] = useState(
    ColumnGeneration(tableDataCtx.headerList)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(); 
  const [PageSize, setPageSize] = useState(10);
  const [dragOver, setDragOver] = useState("");

  const handleSorting = (sortField, sortOrder) => {
    setTableData(HandleSorting(sortField, sortOrder, tableData));
  };

  const onFilterDataEmit = (data) => {
    setTableData(data);
  };

  useEffect(() => {
    setCommonConfig(tableDataCtx.commonConfig);
    setColumn(ColumnGeneration(tableDataCtx.headerList));
    setTableRawdata(tableDataCtx.dataList);
    if (commonConfig && commonConfig.isPagination) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      if (data) {
        setTableData(data.slice(firstPageIndex, lastPageIndex));
      }
    } else {
      setTableData(data);
    }
  }, [currentPage, PageSize, tableDataCtx]);

  //   useEffect(() => {
  //     console.log("effect");
  //     tableData = [...sortingData];
  //   }, [sortingData]);

  return (
    <ZTableWrapper>
      <ZColumnProperties />
      {tableData ? (
        <ZTableActions
          tableData={tableData}
          commonConfig={commonConfig}
          onFilterDataEmit={onFilterDataEmit}
        />
      ) : (
        <></>
      )}
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table
              id="tbl_exporttable_to_xls"
              className={`min-w-full`}
              style={{ borderSpacing: 0 }}
            >
              {columns && columns.length > 0 ? (
                <ZTableHeader
                  columns={columns}
                  commonConfig={commonConfig}
                  handleSorting={handleSorting}
                  dragOver={setDragOver}
                />
              ) : (
                <></>
              )}
              {tableData ? (
                <ZTableRow
                  {...{ columns, tableData, commonConfig }}
                  varient="parent"
                  // dragOver={dragOver}
                />
              ) : (
                ""
              )}
            </table>
          </div>
        </div>
      </div>
      {commonConfig && commonConfig.isPagination ? (
        <ZTablePagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          pageSizeList={pageLength}
          autoPageMinList={autoPageMinList}
          commonConfig={commonConfig}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={setPageSize}
        />
      ) : (
        <></>
      )}
    </ZTableWrapper>
  );
}

export default ZTable;
