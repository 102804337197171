import arrowLeft from "../../assets/icons/arrow_left.svg";
import arrowRight from "../../assets/icons/arrow_right.svg";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { useMemo, useState, Fragment, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ZTablePagination(props) {
  const {
    onPageChange,
    onPageSizeChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSizeList,
    autoPageMinList,
    commonConfig,
  } = props;

  const [pageSize, setSelectedPageSize] = useState(props.pageSize);
  const [selectedMin, setSelectedMin] = useState(autoPageMinList[0]);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {}, [paginationRange]);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const OnPageSizeChange = (e) => {
    setSelectedPageSize(e);
    onPageSizeChange(e);
  };

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav className="flex  items-center justify-between border-t border-gray-200 px-4 py-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          type="button"
          onClick={onPrevious}
          style={{
            fontSize: commonConfig.fontSize,
            fontStyle: commonConfig.fontStyle,
            textDecoration: commonConfig.fontStyle,
            color: commonConfig.color,
          }}
          disabled={currentPage === 1}
          className={`inline-flex mr-3 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:opacity-60`}
        >
          <img src={arrowLeft} className="pr-3" alt="" />
          Previous
        </button>
        <Listbox value={pageSize} onChange={OnPageSizeChange}>
          <div className="relative">
            <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{pageSize}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              // show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {pageSizeList.map((item, index) => (
                  <Listbox.Option
                    key={`pageList--${index}`}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ pageSize, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              pageSize ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {item}
                          </span>
                        </div>

                        {pageSize ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {/* <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              /> */}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      {paginationRange.map((pageNumber, pageInd) => {
        if (pageNumber === DOTS) {
          return (
            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
              ...
            </span>
          );
        }
        return (
          <div className="hidden md:-mt-px md:flex" key={`paging-${pageInd}`}>
            <a
              onClick={() => onPageChange(pageNumber)}
              style={{
                fontSize: commonConfig.fontSize,
                fontStyle: commonConfig.fontStyle,
                textDecoration: commonConfig.fontStyle,
                color: commonConfig.color,
              }}
              className={`flex items-center justify-center cursor-pointer rounded-lg w-10 h-10 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                currentPage == pageNumber ? "text-primary bg-lightRed" : ""
              }`}
            >
              {pageNumber}
            </a>
          </div>
        );
      })}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {commonConfig.isAutoPage ? (
          <Listbox value={selectedMin} onChange={setSelectedMin}>
            <div className="relative">
              <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <span className="flex items-center">
                  <span className="ml-3 block truncate">{selectedMin} Min</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                // show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {autoPageMinList.map((item, index) => (
                    <Listbox.Option
                      key={`pageList--${index}`}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={item}
                    >
                      {({ selectedMin, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selectedMin ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {item} Min
                            </span>
                          </div>

                          {selectedMin ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        ) : (
          <></>
        )}
        <button
          type="button"
          style={{
            fontSize: commonConfig.fontSize,
            fontStyle: commonConfig.fontStyle,
            textDecoration: commonConfig.fontStyle,
            color: commonConfig.color,
          }}
          onClick={onNext}
          disabled={currentPage === lastPage}
          className="inline-flex ml-3 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 disabled:opacity-60  "
        >
          Next
          <img src={arrowRight} alt="" className="pl-3" />
        </button>
      </div>
    </nav>
  );
}

export const DOTS = "...";

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export default ZTablePagination;
