import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ZColorPicker = (props) => {
  const { colorList, selectedColor, onHandleSelectedColor, keyParam } = props;
  const [colorValue, setColorValue] = useState(selectedColor);

  const onSelectColor = (e) => () => {
    setColorValue(e);
    onHandleSelectedColor(e, keyParam);
  };

  // useEffect(() => {
  //   console.log("colorValue", colorValue);
  //   onHandleSelectedColor(colorValue);
  // }, [colorValue]);

  return (
    <div className="relative grid grid-cols-8 gap-y-1 px-2 py-1 rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
      {colorList.map((item) => (
        <div
          key={`colorpicker${item.id}`}
          className={`w-8 h-6 flex  items-center justify-center p-1 cursor-pointer`}
        >
          <span
            value={item.color}
            onClick={onSelectColor(item.color)}
            className={`${
              colorValue == item.color ? "border-sky-600 animate-bounce" : ""
            } hover:border-sky-600 w-4 h-4 rounded-full cursor-pointer hover:border-2`}
            style={{
              backgroundColor: item.color,
            }}
          ></span>
        </div>
      ))}
    </div>
  );
};

export default ZColorPicker;
