import {
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from "react";
import headMenu from "../../assets/icons/arrow-circle-dow.svg";
import downArrow from "../../assets/icons/desc_arrow.svg";
import upArrow from "../../assets/icons/arrorw_up.svg";
import { Popover, Transition } from "@headlessui/react";
import ColumnResizer from "react-table-column-resizer";
import "./table.css";
import TableContext from "../../store/TableContext";
import dragGroupIcon from "../../assets/icons/dot_holder.svg";

const columnMoreOptionList = [
  {
    id: 1,
    value: "isFreeze",
    name: "Freeze column",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    id: 2,
    value: "asc",
    name: "Sort ascending",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    id: 3,
    value: "desc",
    name: "Sort decending",
    icon: require("../../assets/icons/arrow_down.svg"),
  },
  // {
  //   name: "Search",
  //   icon: require("../assets/icons/search.svg"),
  // },
  {
    id: 4,
    value: "filter",
    name: "Filter",
    icon: require("../../assets/icons/filter.svg"),
  },
  {
    id: 5,
    value: "isActive",
    name: "Hide in view",
    icon: require("../../assets/icons/hide_view.svg"),
  },
  {
    id: 6,
    value: "edit",
    name: "Edit property",
    icon: require("../../assets/icons/setting.svg"),
  },
];

const createHeaders = (headers) => {
  const data = headers.map((item) => {
    return {
      ...item,
      columnName: item.columnName,
      chooseColumn: item.chooseColumn,
      chooseColumnIcon: item.chooseColumnIcon,
      chooseColumnType: item.chooseColumnType,
      chooseFrom: item.chooseFrom,
      subType: item.subType,
      sortable: item.sortable,
      isFreeze: item.isFreeze,
      isActive: item.isActive,
    }
  })
  return data

}

function ZTableHeader({ columns, handleSorting, commonConfig }) {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [headers, setHeaders] = useState(createHeaders(columns));
  const [columnReorder, setColumnReorder] = useState(createHeaders(columns));
  const [showFilterIdx, setShowFilterIdx] = useState(-1);
  const [dragOverElem, setDragOver] = useState("");
  const commonConfiguration = commonConfig;
  const ctx = useContext(TableContext);

  const handleDragStart = (idx) => (e) => {
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (e) => e.preventDefault();
  const handleDragEnter = (e) => {
    const { id } = e.target.id;
    setDragOver(id);
  };

  const handleOnDrop = (idx) => (e) => {
    const droppedColIdx = idx;
    const draggedColIdx = e.dataTransfer.getData("colIdx");
    const tempCols = [...headers];
    tempCols[draggedColIdx] = headers[droppedColIdx];
    tempCols[droppedColIdx] = headers[draggedColIdx];
    setColumnReorder(createHeaders(tempCols));
    setDragOver("");
  };

  const onFilterShow = (idx) => () => {
    if (showFilterIdx == idx) {
      setShowFilterIdx(-1);
    } else {
      setShowFilterIdx(idx);
    }
  };

  useEffect(() => {
    ctx.addCommonConfig({
      headerList: columnReorder,
      commonConfig: ctx.commonConfig,
    });
    setHeaders(columnReorder);
    console.log("header",headers)
  }, [columnReorder]);

  /**
   * Handling Sorting
   * @param {*} chooseColumn
   */
  const handleSortingChange = (chooseColumn) => {
    const sortOrder =
      chooseColumn === sortField && order === "asc" ? "desc" : "asc";
    setSortField(chooseColumn);
    setOrder(sortOrder);
    handleSorting(chooseColumn, sortOrder);
  };

  const onHandleMoreOptions = (RI, object, chooseColumn) => () => {

    switch (object.value) {
      case "isFreeze":
        break;
      case "asc":
        setOrder(object.value);
        handleSortingChange(chooseColumn);
        break;
      case "desc":
        setOrder(object.value);
        handleSortingChange(chooseColumn);
        break;
      case "filter":
        onTriggerFilter(chooseColumn);
        break;
      case "isActive":
        const newArray = columnReorder.map((item, i) => {
          if (i === RI) {
            return { ...item, [object.value]: false };
          } else {
            return item;
          }
        });
        setColumnReorder(newArray);
        break;
      case "edit":
        console.log("obj", object)
        const newObj = { ...object, "index": RI }
        ctx.openColumnConfig(newObj, RI);
        break;
      default:
        break;
    }
    setShowFilterIdx(-1);
    // setShowFilterIdx(-1);
  };

  const onTriggerFilter = (chooseColumn) => {
    ctx.addAdvancedFilter({ columnName: chooseColumn });
  };

  return (
    <thead className="bg-white" key="thead" >
      <tr key="theadRow" >
        {headers.map(
          (
            {
              columnName,
              chooseColumn,
              sortable,
              chooseColumnIcon,
              chooseColumnType,
              isActive,
              isFreeze,
              standalone,
              fontSize,
              fontStyle,
              color,
              columnBG
            },
            i
          ) => {
            const sortState = sortable
              ? sortField === chooseColumn && order === "asc"
                ? "up"
                : sortField === chooseColumn && order === "desc"
                  ? "down"
                  : "default"
              : "";
            return (
              <>
                {isActive ? (
                  <>
                    <th
                      scope="col"
                      id={chooseColumn}
                      key={`tableheader--${i}`}
                      draggable
                      onDragStart={handleDragStart(i)}
                      onDrop={handleOnDrop(i)}
                      onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                      // dragOver={chooseColumn === dragOverElem}

                      // onMouseEnter={() => onFilterShow("enter")}
                      // onMouseLeave={() => onFilterShow("leave")}
                      style={{
                        color: color ? color : commonConfiguration.columnColour,
                        fontSize: fontSize ? fontSize : commonConfiguration.columnFontSize,
                        fontStyle: fontStyle ? fontStyle : commonConfiguration.columnFontStyle,
                        textDecoration: fontStyle ? fontStyle : commonConfiguration.columnFontStyle,
                        backgroundColor: columnBG ? columnBG : commonConfiguration.columnHeaderBG,
                      }}
                      className={`${isActive ? "visible" : "invisible"
                        } w-48  group relative transition-all duration-300 cursor-pointer border-b border-gray-200 z-10 py-3.5 h-11 pl-4 pr-4 text-left text-xs font-inter font-medium text-gray-400 hover:bg-gray-50  `}
                    >
                      {chooseColumnType == "checkbox" ? (
                        <>
                          <img
                            src={dragGroupIcon}
                            className="mr-3 cursor-move"
                            alt=""
                          />
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                          />
                        </>
                      ) : (
                        <>
                          <a className="inline-flex">
                            <img
                              src={dragGroupIcon}
                              className="mr-3 cursor-move focus-visible:outline-none invisible group-hover:visible group-focus:visible"
                              alt=""
                            />
                            {chooseColumnIcon ? (
                              <img
                                className="mr-2"
                                src={chooseColumnIcon}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                              {columnName}
                            </span>
                            <span
                              onClick={
                                sortable
                                  ? () => handleSortingChange(chooseColumn)
                                  : null
                              }
                              className="focus-visible:outline-none invisible ml-2 flex-none rounded text-gray-500 group-hover:visible group-focus:visible"
                            >
                              {sortState === "down" ||
                                sortState == "default" ? (
                                <img src={downArrow} alt="Sort Decending" />
                              ) : sortState === "up" ? (
                                <img src={upArrow} alt="Sort Ascecending" />
                              ) : (
                                ""
                              )}
                            </span>
                          </a>
                          <Popover className="sort-action ">
                            <Popover.Button
                              onClick={onFilterShow(i)}
                              className="focus-visible:outline-none invisible group-hover:visible group-focus:visible"
                            >
                              <img src={headMenu} alt="Header menu arrow" />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              show={showFilterIdx === i}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="w-60 absolute left-1/2 z-50 top-14 max-w-xs transform px-2 sm:px-0">
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
                                  <div className="relative grid bg-white">
                                    {columnMoreOptionList.map((item, index) => (
                                      <div
                                        key={`column--more--options${item.name}`}
                                        onClick={onHandleMoreOptions(
                                          i,
                                          item,
                                          chooseColumn
                                        )}
                                        className="cursor-pointer flex items-center w-100 py-3 px-5 rounded-md h-10 transition duration-150 ease-in-out hover:bg-lightRed"
                                      >
                                        <img
                                          src={item.icon.default}
                                          className="pr-3"
                                          alt=""
                                        />
                                        <p className="text-sm font-medium text-gray-700">
                                          {item.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        </>
                      )}
                    </th>
                    <ColumnResizer
                      key={`resizer--${i}`}
                      className={`bg-white w-0.5 h-11 hover:bg-indigo-600 `}
                    />
                  </>
                ) : (
                  null
                )}
              </>
            );
          }
        )}
      </tr>
    </thead>
  );
}

export default ZTableHeader;
