import { Transition, Dialog, Popover, Listbox, Switch } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
import TableContext from "../../store/TableContext";
import ZPermissionConfig from "./ZPermissionConfig";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useContext } from "react";
import { useEffect } from "react";
import ZColorPicker from "./ZColorPicker";
import ZColumnIcon from "./ZColumnIcon";
import plusActive from "../../assets/icons/plus_primary.svg";
import ChipInput from "./ZChipInput";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const solutions = [
  {
    name: "Freeze column",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    name: "Sort ascending",
    icon: require("../../assets/icons/arrorw_up.svg"),
  },
  {
    name: "Sort decending",
    icon: require("../../assets/icons/arrow_down.svg"),
  },
  {
    name: "Search",
    icon: require("../../assets/icons/search.svg"),
  },
  {
    name: "Filter",
    icon: require("../../assets/icons/filter.svg"),
  },
  {
    name: "Hide in view",
    icon: require("../../assets/icons/hide_view.svg"),
  },
  {
    name: "Edit property",
    icon: require("../../assets/icons/setting.svg"),
  },
];

const columnType = [
  {
    id: 1,
    value: "text",
    name: "Text",
  },
  {
    id: 2,
    value: "number",
    name: "Number",
  },
  {
    id: 3,
    value: "select",
    name: "select",
  },
  {
    id: 4,
    value: "multiselect",
    name: "Multi-select",
  },
  {
    id: 5,
    value: "status",
    name: "Status",
  },
  {
    id: 6,
    value: "date",
    name: "Date",
  },
  {
    id: 7,
    value: "files",
    name: "Files",
  },
  {
    id: 8,
    value: "URL",
    name: "URL",
  },
  {
    id: 10,
    value: "email",
    name: "Email",
  },
  {
    id: 11,
    value: "phone",
    name: "Phone",
  },
  {
    id: 12,
    value: "tags",
    name: "Tags",
  },
];

const colorPallate = [
  {
    id: 1,
    color: "#12B76A",
  },
  {
    id: 2,
    color: "#0BA5EC",
  },
  {
    id: 3,
    color: "#6172F3",
  },
  {
    id: 4,
    color: "#7A5AF8",
  },
  {
    id: 5,
    color: "#9E77ED",
  },
  {
    id: 6,
    color: "#4E5BA6",
  },
  {
    id: 7,
    color: "#EE46BC",
  },
  {
    id: 8,
    color: "#F63D68",
  },
  {
    id: 9,
    color: "#FB6514",
  },
  {
    id: 10,
    color: "#D1FADF",
  },
  {
    id: 11,
    color: "#E0F2FE",
  },
  {
    id: 12,
    color: "#E0EAFF",
  },
  {
    id: 13,
    color: "#EBE9FE",
  },
  {
    id: 14,
    color: "#F4EBFF",
  },
  {
    id: 15,
    color: "#EAECF5",
  },
  {
    id: 16,
    color: "#FCE7F6",
  },
  {
    id: 17,
    color: "#FFE4E8",
  },
  {
    id: 18,
    color: "#FFEAD5",
  },
  {
    id: 19,
    color: "#A6F4C5",
  },
  {
    id: 20,
    color: "#B9E6FE",
  },
  {
    id: 21,
    color: "#C7D7FE",
  },
  {
    id: 22,
    color: "#D9D6FE",
  },
  {
    id: 23,
    color: "#E9D7FE",
  },
  {
    id: 24,
    color: "#D5D9EB",
  },
  {
    id: 25,
    color: "#FCCEEE",
  },
  {
    id: 26,
    color: "#FECDD6",
  },
  {
    id: 27,
    color: "#FDDCAB",
  },
  {
    id: 28,
    color: "#6CE9A6",
  },
  {
    id: 29,
    color: "#7CD4FD",
  },
  {
    id: 30,
    color: "#A4BCFD",
  },
  {
    id: 31,
    color: "#BDB4FE",
  },
  {
    id: 32,
    color: "#D6BBFB",
  },
  {
    id: 33,
    color: "#AFB5D9",
  },
  {
    id: 34,
    color: "#FAA7E0",
  },
  {
    id: 35,
    color: "#FEA3B4",
  },
  {
    id: 36,
    color: "#FEB273",
  },
  {
    id: 37,
    color: "#32D583",
  },
  {
    id: 38,
    color: "#36BFFA",
  },
  {
    id: 39,
    color: "#8098F9",
  },
  {
    id: 40,
    color: "#9B8AFB",
  },
  {
    id: 41,
    color: "#B692F6",
  },
  {
    id: 42,
    color: "#717BBC",
  },
  {
    id: 43,
    color: "#F670C7",
  },
  {
    id: 44,
    color: "#FD6F8E",
  },
  {
    id: 45,
    color: "#FD853A",
  },
  {
    id: 46,
    color: "#12B76A",
  },
  {
    id: 47,
    color: "#0BA5EC",
  },
  {
    id: 48,
    color: "#6172F3",
  },
  {
    id: 49,
    color: "#7A5AF8",
  },
  {
    id: 50,
    color: "#9E77ED",
  },
  {
    id: 51,
    color: "#4E5BA6",
  },
  {
    id: 52,
    color: "#EE46BC",
  },
  {
    id: 53,
    color: "#F63D68",
  },
  {
    id: 54,
    color: "#FB6514",
  },
  {
    id: 55,
    color: "#039855",
  },
  {
    id: 56,
    color: "#0086C9",
  },
  {
    id: 57,
    color: "#444CE7",
  },
  {
    id: 58,
    color: "#6938EF",
  },
  {
    id: 59,
    color: "#7F56D9",
  },
  {
    id: 60,
    color: "#3E4784",
  },
  {
    id: 61,
    color: "#DD2590",
  },
  {
    id: 62,
    color: "#E31B54",
  },
  {
    id: 63,
    color: "#EC4A0A",
  },
  {
    id: 64,
    color: "#027A48",
  },
  {
    id: 65,
    color: "#026AA2",
  },
  {
    id: 66,
    color: "#3538CD",
  },
  {
    id: 67,
    color: "#5925DC",
  },
  {
    id: 68,
    color: "#6941C6",
  },
  {
    id: 69,
    color: "#363F72",
  },
  {
    id: 70,
    color: "#C11574",
  },
  {
    id: 71,
    color: "#C01048",
  },
  {
    id: 72,
    color: "#C4320A",
  },
  {
    id: 73,
    color: "#05603A",
  },
  {
    id: 74,
    color: "#1849A9",
  },
  {
    id: 75,
    color: "#2D31A6",
  },
  {
    id: 76,
    color: "#4A1FB8",
  },
  {
    id: 77,
    color: "#53389E",
  },
  {
    id: 78,
    color: "#293056",
  },
  {
    id: 79,
    color: "#9E165F",
  },
  {
    id: 80,
    color: "#A11043",
  },
  {
    id: 81,
    color: "#9C2A10",
  },
  {
    id: 82,
    color: "#054F31",
  },
  {
    id: 83,
    color: "#194185",
  },
  {
    id: 84,
    color: "#2D3282",
  },
  {
    id: 85,
    color: "#3E1C96",
  },
  {
    id: 86,
    color: "#42307D",
  },
  {
    id: 87,
    color: "#101323",
  },
  {
    id: 88,
    color: "#851651",
  },
  {
    id: 89,
    color: "#89123E",
  },
  {
    id: 90,
    color: "#7E2410",
  },
  {
    id: 91,
    color: "transparant",
  },
];

const permissionList = [
  {
    id: 1,
    name: "Admin",
    isView: true,
    isEdit: false,
  },
  {
    id: 2,
    name: "Manager",
    isView: true,
    isEdit: false,
  },
  {
    id: 3,
    name: "User",
    isView: true,
    isEdit: false,
  },
];

const dbList = [
  { id: "1", name: "userlist.db" },
  { id: "2", name: "comapny.db" },
  { id: "3", name: "customer.db" },
  { id: "4", name: "master.db" },
  { id: "5", name: "record.db" },
];
const dbColumnList = [
  { id: "1", value: "name", name: "User Name" },
  { id: "2", value: "isActive", name: "Status" },
  { id: "3", value: "email", name: "Email" },
  { id: "4", value: "gender", name: "Gender" },
  { id: "5", value: "company", name: "Company" },
  { id: "6", value: "tags", name: "Tags" },
  { id: "7", value: "balance", name: "Amount" },
  { id: "8", value: "registered", name: "Updated On" },
];
const dataFormatOptions = [
  { id: 1, name: "Text is exactly" },
  { id: 2, name: "Text in" },
  { id: 3, name: "Text not in" },
  { id: 4, name: "Is empty" },
  { id: 5, name: "Is not empty" },
  { id: 6, name: "Text starts with" },
  { id: 7, name: "Less than" },
  { id: 8, name: "Greater than" },
  { id: 9, name: "Is between" },
];
const dataFormatModel = {
  condition: { id: 2, name: "Text in" },
  columns: [],
  colour: "",
  fontSize: "",
  fontStyle: "",
  cellBGColour: "",
};
const operatorTypeList = [
  {
    id: 1,
    name: "Add",
    description: "Sum of two numbers",
  },
  {
    id: 2,
    name: "Subtract",
    description: "Difference of two numbers",
  },
  {
    id: 3,
    name: "Multiply",
    description: "Product of two numbers",
  },
  {
    id: 4,
    name: "Divide",
    description: "One number divided by another",
  },
  {
    id: 5,
    name: "Percentage",
    description: "Sum of two numbers",
  },
  {
    id: 6,
    name: "Text format",
    description: "Merge and format two or more coluns",
  },
  {
    id: 7,
    name: "Date format",
    description: "Format date string",
  },
  {
    id: 8,
    name: "Date addition",
    description: "Add n-days to a Date.First operand be a date column",
  },
  {
    id: 9,
    name: "Date subtraction",
    description: "Sub n-days a DAte.First operand be a date column",
  },
  {
    id: 10,
    name: "Date difference",
    description: "Difference between two date values",
  },
  {
    id: 11,
    name: "Minutes difference",
    description: "Subtract two dates and returns the diffrence in minutes",
  },
];
const subTypeList = [{ id: "1", name: "Status" }];

const tabList = [
  { name: "General", href: "", current: true },
  { name: "Permissions", href: "", current: false },
];

const dataTabList = [
  { name: "Data Formatting", href: "", current: true },
  { name: "Data Formula", href: "", current: false },
];

const dataFormulaModel = {
  operatorType: {
    id: 2,
    name: "Subtract",
    description: "Difference of two numbers",
  },
  newColumnName: "",
  columnFrom: {},
  columnTo: {},
};


const ZColumnProperties = () => {

  const columnPropCtx = useContext(TableContext);
  const [addColumn, setAddColumn] = useState({
    color: "",
    fontSize: "",
    fontStyle: "",
    columnBG: "",
    columnName: "",
    chooseFrom: "",
    chooseColumn: "",
    chooseColumnType: "",
    chooseColumnIcon: "",
    isActive: true,
    subType: ""
  });

  const [open, setOpen] = useState(false);
  const reference = useRef()
  const [currentTab, setCurrentTab] = useState(0);
  const [currentDataTab, setCurrentDataTab] = useState(0);
  const [dataFormat, setDataFormat] = useState(dataFormatModel);
  const [dataFormula, setDataFormula] = useState(
    dataFormulaModel
  );
  const [chooseColumnFrom, setChooseColumnFrom] = useState(columnPropCtx.headerList[1]);
  const [chooseColumnTo, setChooseColumnTo] = useState(columnPropCtx.headerList[1]);
  const [isOpenColor, setOpenColor] = useState(false);
  const [isOpenColorDataFormat, setOpenColorDataFormat] = useState(false);
  const [colorPcikerHandler, setOpenColumnPicker] = useState(true);
  const ctx = useContext(TableContext);


  useEffect(() => {
    setOpen(columnPropCtx.isOpenColumnConfig);
    setAddColumn((latest) => {
      let index = columnPropCtx?.columnProperties?.index

      const latestObj = {
        ...latest,
        color: columnPropCtx?.headerList?.[index]?.color === undefined ? columnPropCtx.commonConfig.columnColour : columnPropCtx?.headerList?.[index]?.color,
        fontSize: columnPropCtx?.headerList?.[index]?.fontSize === undefined ? columnPropCtx.commonConfig.columnFontSize : columnPropCtx?.headerList?.[index]?.fontSize,
        fontStyle: columnPropCtx?.headerList?.[index]?.fontStyle === undefined ? columnPropCtx.commonConfig.columnFontStyle : columnPropCtx?.headerList?.[index]?.fontStyle,
        columnBG: columnPropCtx?.headerList?.[index]?.columnBG === undefined ? columnPropCtx.commonConfig.columnBG : columnPropCtx?.headerList?.[index]?.columnBG,
        columnName: columnPropCtx?.headerList?.[index]?.columnName
          ? columnPropCtx?.headerList?.[index]?.columnName
          : "",
        chooseFrom: columnPropCtx?.headerList?.[index]?.chooseFrom
          ? columnPropCtx?.headerList?.[index]?.chooseFrom
          : "",
        chooseColumn: columnPropCtx?.headerList?.[index]?.chooseColumn
          ? columnPropCtx?.headerList?.[index]?.chooseColumn
          : "",
        chooseColumnType: columnPropCtx?.headerList?.[index]?.chooseColumnType
          ? columnPropCtx?.headerList?.[index]?.chooseColumnType
          : "",
        chooseColumnIcon: columnPropCtx?.headerList?.[index]?.chooseColumnIcon
          ? columnPropCtx?.headerList?.[index]?.chooseColumnIcon
          : "",
        isActive: columnPropCtx?.headerList?.[index]?.isActive
          ? columnPropCtx?.headerList?.[index]?.isActive
          : "",
        subType: columnPropCtx?.headerList?.[index]?.subType
          ? columnPropCtx?.headerList?.[index]?.subType
          : ""

      }


      return latestObj



    })

    setDataFormat((latest) => {
      let index = columnPropCtx?.columnProperties?.index
      return columnPropCtx?.headerList?.[index]?.dataFormat ? columnPropCtx?.headerList?.[index]?.dataFormat : dataFormatModel
    });
  }, [columnPropCtx]);

  const openColorHandler = (keyParam) => () => {
    if (keyParam == "color") {
      setOpenColor(true);
    }
    if (keyParam == "colour") {
      setOpenColorDataFormat(!isOpenColorDataFormat);
    }
  };

  const OnHandleSelectedColor = (pickedColor, keyParam) => {

    setAddColumn({
      ...addColumn,
      [keyParam]: pickedColor,
    });
    if (keyParam == "color") {
      setOpenColor(false);
    }
  };

  const updateColumnList = (fieldName) => (e) => {
    setAddColumn({ ...addColumn, [fieldName]: e });
  };

  const updateStyles = (styleProperty, value) => () => {

    setAddColumn({
      ...addColumn,
      [styleProperty]: value,
    });
  };

  const updateColumn = (category) => (e) => {
    setAddColumn({
      ...addColumn,
      [e.target.name]: category == "text" ? e.target.value : e.target.checked,
    });
  };

  const onHandleChangeColumnIcon = (selectedIcon) => {
    setOpenColumnPicker(false);
    setAddColumn({ ...addColumn, chooseColumnIcon: selectedIcon });
  };

  const onCloseConfigHandler = () => {
    let commonConfig = localStorage.getItem("commonconfig");
    let columnConfig = localStorage.getItem("columnConfig");
    if (commonConfig && columnConfig) {
      ctx.addCommonConfig({
        headerList: JSON.parse(columnConfig),
        commonConfig: JSON.parse(commonConfig),
      });
    }
    setOpen(false);
  };
  const editColumn = () => {
    let index = columnPropCtx?.columnProperties?.index


    const data = columnPropCtx?.headerList?.map((data, indexInner) => {

      if (indexInner === index) {

        return {
          ...data, color: addColumn.color,
          fontSize: addColumn.fontSize,
          fontStyle: addColumn.fontStyle,
          columnBG: addColumn.columnBG,
          columnName: addColumn.columnName
            ? addColumn?.columnName
            : "",
          chooseFrom: addColumn?.chooseFrom
            ? addColumn?.chooseFrom
            : "",
          chooseColumn: addColumn?.chooseColumn
            ? addColumn?.chooseColumn
            : "",
          chooseColumnType: addColumn?.chooseColumnType
            ? addColumn?.chooseColumnType
            : "",
          chooseColumnIcon: addColumn?.chooseColumnIcon
            ? addColumn?.chooseColumnIcon
            : "",
          isActive: addColumn?.isActive
            ? addColumn?.isActive
            : "",
          subType: addColumn?.subType
            ? addColumn?.subType
            : ""
        }

      }
      else {
        return data
      }




    })

    localStorage.setItem("columnConfig", JSON.stringify(data));
  }

  const changeDataFormat = (a, b) => {

    if (b === "colour") {
      setOpenColorDataFormat((latest) => {
        return !latest
      }

      )
    }
    setDataFormat((latest) => {
      return { ...latest, [b]: a }
    })
  }
  const saveDataFormat = () => {
    let index = columnPropCtx?.columnProperties?.index
    const data = columnPropCtx?.headerList?.map((data, indexInner) => {
      if (indexInner === index) {
        return { ...data, "dataFormat": dataFormat }
      }
      else {
        return data
      }
    })
    localStorage.setItem("columnConfig", JSON.stringify(data));
  }

  const changeFormulaModel = (e, key) => {
    setDataFormula((latest) => {
      return { ...latest, [key]: e }
    })
  }

  const saveDataFormula = () => {
    const dataList = columnPropCtx.dataList.map((data) => {
      let output
      switch (dataFormula?.operatorType?.name) {
        case "Add":
          output = data[dataFormula?.columnFrom?.chooseColumn] + data[dataFormula?.columnTo?.chooseColumn]
          break;
        case "Subtract":
          output = data[dataFormula?.columnFrom?.chooseColumn] - data[dataFormula?.columnTo?.chooseColumn]
          break;
        case "Multiply":
          output = data[dataFormula?.columnFrom?.chooseColumn] * data[dataFormula?.columnTo?.chooseColumn]
          break;
        case "Divide":
          output = data[dataFormula?.columnFrom?.chooseColumn] / data[dataFormula?.columnTo?.chooseColumn]
          break;
        default:
          break;
      }
      return { ...data, [dataFormula?.newColumnName]: String(output) }
    })
    const newObj = {
      chooseColumn: dataFormula?.newColumnName,
      chooseColumnIcon: "",
      chooseColumnType: "number",
      chooseFrom: "userlist.db",
      columnName: dataFormula?.newColumnName,
      isActive: true,
      isFreeze: false,
      sortable: true,
      subType: ""
    }
    const headerList = [...columnPropCtx.headerList, newObj];
    localStorage.setItem("columnConfig", JSON.stringify(headerList));
    localStorage.setItem("dataList", JSON.stringify(dataList));
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-3xl font-bold text-gray-900">
                          Column Properties
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md border p-1 border-gray-300 bg-white text-gray-400 hover:text-gray-500"
                            onClick={onCloseConfigHandler}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav
                          className="-mb-px flex space-x-6"
                          x-descriptions="Tab component"
                        >
                          {tabList.map((tab, index) => (
                            <a
                              key={tab.name}
                              onClick={(e) => setCurrentTab(index)}
                              className={classNames(
                                index == currentTab
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    {currentTab == 0 ? (
                      <form className="divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 bg-white  px-4 py-5  sm:p-6">
                          <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                              <div className="flex justify-between">
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Colour
                                  </label>
                                  <div className="mt-1">
                                    <span className="  inline-flex rounded-md shadow-sm">
                                      <Popover className="relative">
                                        <Popover.Button ref={reference}   >
                                          <span

                                            onClick={openColorHandler("color")}
                                            className="relative h-10 rounded-md -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                                          >
                                            <div
                                              value={addColumn.columnColour}
                                              onClick={openColorHandler(
                                                "color"
                                              )}
                                              style={{
                                                backgroundColor:
                                                  addColumn.color,
                                              }}
                                              className={`w-4 h-4 rounded-full bg-primary cursor-pointer border hover:border-gray-800`}
                                            ></div>
                                          </span>
                                        </Popover.Button>
                                        <Transition
                                          as={Fragment}
                                          show={isOpenColor}

                                          enter="transition ease-out duration-200"
                                          enterFrom="opacity-0 translate-y-1"
                                          enterTo="opacity-100 translate-y-0"
                                          leave="transition ease-in duration-150"
                                          leaveFrom="opacity-100 translate-y-0"
                                          leaveTo="opacity-0 translate-y-1"
                                        >
                                          <Popover.Panel className="absolute    left-1/2 z-10 mt-3 w-56 transform">
                                            <ZColorPicker
                                              colorList={colorPallate}
                                              selectedColor={addColumn.color}
                                              keyParam="color"
                                              onHandleSelectedColor={
                                                OnHandleSelectedColor
                                              }
                                            />
                                          </Popover.Panel>
                                        </Transition>
                                      </Popover>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Font Size
                                  </label>
                                  <div className="mt-1">
                                    <span
                                      className="inline-flex rounded-md shadow-sm"
                                      style={{ maxHeight: "38px" }}
                                    >
                                      <button

                                        type="button"
                                        style={{ fontSize: "10px" }}
                                        onClick={updateStyles(
                                          "fontSize",
                                          "10px"
                                        )}
                                        className={classNames(
                                          addColumn.fontSize == "10px"
                                            ? "bg-gray-200 "
                                            : "",
                                          "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2  font-medium  text-gray-700 hover:bg-gray-200  "
                                        )}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={updateStyles(
                                          "fontSize",
                                          "14px"
                                        )}
                                        className={`${addColumn.fontSize == "14px"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={updateStyles(
                                          "fontSize",
                                          "18px"
                                        )}
                                        className={`"${addColumn.fontSize == "18px"
                                          ? " bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Font Style
                                  </label>
                                  <div className="mt-1">
                                    <span className=" inline-flex rounded-md shadow-sm">
                                      <button
                                        type="button"
                                        onClick={updateStyles(
                                          "fontStyle",
                                          "italic"
                                        )}
                                        className={`${addColumn.fontStyle == "italic"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative inline-flex items-center rounded-l-md border italic border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={updateStyles(
                                          "fontStyle",
                                          "bold"
                                        )}
                                        className={`${addColumn.fontStyle == "bold"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-bold  text-gray-700 hover:bg-gray-200 `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={updateStyles(
                                          "fontStyle",
                                          "underline"
                                        )}
                                        className={`${addColumn.fontStyle == "underline"
                                          ? " bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 underline hover:bg-gray-50  `}
                                      >
                                        A
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox
                                value={addColumn.columnBG}
                                onChange={(e) =>
                                  setAddColumn({ ...addColumn, columnBG: e })
                                }
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Row Background Colour
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center">
                                      <span
                                        style={{
                                          backgroundColor: addColumn.columnBG,
                                        }}
                                        className={classNames(
                                          "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                                        )}
                                      />
                                      <span className="ml-3 block truncate">
                                        {addColumn.columnBG}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {colorPallate.map((color) => (
                                        <Listbox.Option
                                          key={color.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={color.color}
                                        >
                                          <div className="flex items-center">
                                            <span
                                              style={{
                                                backgroundColor: color.color,
                                              }}
                                              className={classNames(
                                                "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                                              )}
                                              aria-hidden="true"
                                            />
                                            <span
                                              className={classNames(
                                                addColumn.columnBG ==
                                                  color.color
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {color.color}
                                            </span>
                                            {addColumn.columnBG ==
                                              color.color ? (
                                              <span
                                                className={classNames(
                                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>

                            <div className="sm:col-span-6">
                              <Listbox
                                value={addColumn.chooseFrom}
                                name="chooseFrom"
                                onChange={updateColumnList("chooseFrom")}
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Column Form {addColumn.chooseFrom}
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center h-4">
                                      <span className="ml-3 block truncate">
                                        {addColumn.chooseFrom}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {dbList &&
                                        dbList.map((db) => (
                                          <Listbox.Option
                                            key={db.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? "text-white bg-indigo-600"
                                                  : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                              )
                                            }
                                            value={db.value}
                                          >
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  addColumn.chooseFrom ==
                                                    db.value
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {db.name}
                                              </span>
                                              {addColumn.chooseFrom ==
                                                db.value ? (
                                                <span
                                                  className={classNames(
                                                    "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox
                                value={addColumn.chooseColumn}
                                name="chooseColumn"
                                onChange={updateColumnList("chooseColumn")}
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Choose Column
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center h-4">
                                      <span className="ml-3 block truncate">
                                        {addColumn.chooseColumn}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {dbColumnList &&
                                        dbColumnList.map((db) => (
                                          <Listbox.Option
                                            key={db.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? "text-white bg-indigo-600"
                                                  : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                              )
                                            }
                                            value={db.value}
                                          >
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  addColumn.chooseColumn ==
                                                    db.value
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {db.name}
                                              </span>
                                              {addColumn.chooseColumn ==
                                                db.value ? (
                                                <span
                                                  className={classNames(
                                                    "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="sm:col-span-1 mr-3">
                              <label
                                htmlFor="first-name"
                                className="block text-xs font-bold text-gray-700"
                              >
                                Icons
                              </label>
                              <Popover className="relative mt-2 w-11 h-10">
                                <Popover.Button>
                                  <span
                                    onClick={() => setOpenColumnPicker(true)}
                                    className="relative rounded-md w-full -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                  >
                                    {addColumn &&
                                      addColumn.chooseColumnIcon != "" ? (
                                      <img
                                        className="w-5 h-2.5"
                                        src={addColumn.chooseColumnIcon}
                                        alt=""
                                      />
                                    ) : (
                                      <span>Aa</span>
                                    )}
                                  </span>
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  show={colorPcikerHandler.isOpenColorHandler}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute z-10 mt-3 w-72 px-2 sm:px-0">
                                    <ZColumnIcon
                                      IconList={solutions}
                                      existingIcon={addColumn.chooseColumnIcon}
                                      triggerSelectedIcon={
                                        onHandleChangeColumnIcon
                                      }
                                    />
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </div>
                            <div className="sm:col-span-5">
                              <Listbox
                                value={addColumn.chooseColumnType}
                                name="chooseColumnType"
                                onChange={updateColumnList("chooseColumnType")}
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Choose Column Type
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center h-4">
                                      <span className="ml-3 block truncate">
                                        {addColumn.chooseColumnType}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {columnType &&
                                        columnType.map((type) => (
                                          <Listbox.Option
                                            key={type.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? "text-white bg-indigo-600"
                                                  : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                              )
                                            }
                                            value={type.value}
                                          >
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  addColumn.chooseColumnType ==
                                                    type.value
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {type.name}
                                              </span>
                                              {addColumn.chooseColumnType ==
                                                type.value ? (
                                                <span
                                                  className={classNames(
                                                    "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox
                                value={addColumn.subType}
                                name="subType"
                                onChange={updateColumnList("subType")}
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Sub Type
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center h-4">
                                      <span className="ml-3 block truncate">
                                        {addColumn.subType}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {subTypeList &&
                                        subTypeList.map((subType) => (
                                          <Listbox.Option
                                            key={subType.id}
                                            className={({ active }) =>
                                              classNames(
                                                active
                                                  ? "text-white bg-indigo-600"
                                                  : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                              )
                                            }
                                            value={subType.name}
                                          >
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  addColumn.subType ==
                                                    subType.name
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {subType.name}
                                              </span>
                                              {addColumn.subType ==
                                                subType.name ? (
                                                <span
                                                  className={classNames(
                                                    "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <div className="relative flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    name="isActive"
                                    type="checkbox"
                                    checked={addColumn.isActive}
                                    onChange={updateColumn("checkbox")}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-gray-700"
                                  >
                                    Active / Show Column
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <button
                            onClick={editColumn}
                            type="button"
                            className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
                          >
                            Update
                          </button>
                        </div>
                        <div className="border-b pt-3 border-gray-200">
                          <div className="px-6">
                            <nav
                              className="-mb-px flex space-x-6"
                              x-descriptions="Tab component"
                            >
                              {dataTabList.map((tab, index) => (
                                <a
                                  key={tab.name}
                                  onClick={(e) => setCurrentDataTab(index)}
                                  className={classNames(
                                    index == currentDataTab
                                      ? "border-indigo-500 text-indigo-600"
                                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                    "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                                  )}
                                >
                                  {tab.name}
                                </a>
                              ))}
                            </nav>
                          </div>
                        </div>
                        {currentDataTab === 0 ? (
                          <div className=" bg-gray-50  px-4 py-5  sm:p-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            {/* <div className="sm:col-span-6">
                              <span className="cursor-pointer flex text-sm font-bold text-primary">
                                <img src={plusActive} className="mr-2" alt="" />
                                Add Data Formatting
                              </span>
                            </div> */}
                            <div className="sm:col-span-6">
                              <Listbox value={dataFormat.condition.name} onChange={(e) => changeDataFormat(e, "condition")}>
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                                      Formatting cells if
                                    </Listbox.Label>
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="flex items-center">
                                          <span className="block truncate">
                                            {dataFormat.condition.name}
                                          </span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {dataFormatOptions.map((item) => (
                                            <Listbox.Option
                                              key={item.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "text-white bg-indigo-600"
                                                    : "text-gray-900",
                                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                                )
                                              }
                                              value={item}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <div className="flex items-center">
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "block truncate"
                                                      )}
                                                    >
                                                      {item.name}
                                                    </span>
                                                  </div>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? "text-white"
                                                          : "text-indigo-600",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                      )}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <ChipInput columnValue={dataFormat.columns} onSelectLanguage={(e) => changeDataFormat(e, "columns")} />
                            </div>
                            <div className="sm:col-span-6">
                              <div className="flex justify-between">
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Colourgy
                                  </label>
                                  <div className="mt-1">
                                    <span className="  inline-flex rounded-md shadow-sm">
                                      <Popover className="relative">
                                        <Popover.Button>
                                          <span
                                            onClick={openColorHandler("colour")}
                                            className="relative h-10 rounded-md -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                                          >
                                            <div
                                              value={dataFormat.colour}
                                              onClick={openColorHandler(
                                                "colour"
                                              )}
                                              style={{
                                                backgroundColor:
                                                  dataFormat.colour,
                                              }}
                                              className={`w-4 h-4 rounded-full bg-primary cursor-pointer border hover:border-gray-800`}
                                            ></div>
                                          </span>
                                        </Popover.Button>
                                        <Transition
                                          as={Fragment}
                                          show={isOpenColorDataFormat}
                                          enter="transition ease-out duration-200"
                                          enterFrom="opacity-0 translate-y-1"
                                          enterTo="opacity-100 translate-y-0"
                                          leave="transition ease-in duration-150"
                                          leaveFrom="opacity-100 translate-y-0"
                                          leaveTo="opacity-0 translate-y-1"
                                        >
                                          <Popover.Panel className="absolute    left-1/2 z-10 mt-3 w-56 transform">
                                            <ZColorPicker
                                              colorList={colorPallate}
                                              selectedColor={dataFormat.colour}
                                              keyParam="color"
                                              onHandleSelectedColor={
                                                (e) => changeDataFormat(e, "colour")
                                              }

                                            />
                                          </Popover.Panel>
                                        </Transition>
                                      </Popover>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Font Size
                                  </label>
                                  <div className="mt-1">
                                    <span
                                      className="inline-flex rounded-md shadow-sm"
                                      style={{ maxHeight: "38px" }}
                                    >
                                      <button
                                        type="button"
                                        style={{ fontSize: "10px" }}
                                        onClick={() => changeDataFormat(
                                          "10px",
                                          "fontSize"
                                        )}
                                        className={classNames(
                                          dataFormat.fontSize == "10px"
                                            ? "bg-gray-200 "
                                            : "",
                                          "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2  font-medium  text-gray-700 hover:bg-gray-200  "
                                        )}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => changeDataFormat(
                                          "14px",
                                          "fontSize"
                                        )}
                                        className={`${dataFormat.fontSize == "14px"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => changeDataFormat(
                                          "18px",
                                          "fontSize"
                                        )}
                                        className={`"${dataFormat.fontSize == "18px"
                                          ? " bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="city"
                                    className="block text-xs font-bold text-gray-700"
                                  >
                                    Font Style
                                  </label>
                                  <div className="mt-1">
                                    <span className=" inline-flex rounded-md shadow-sm">
                                      <button
                                        type="button"
                                        onClick={() => changeDataFormat(
                                          "italic",
                                          "fontStyle"
                                        )}
                                        className={`${dataFormat.fontStyle == "italic"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative inline-flex items-center rounded-l-md border italic border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => changeDataFormat(
                                          "bold",
                                          "fontStyle"
                                        )}
                                        className={`${dataFormat.fontStyle == "bold"
                                          ? "bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-bold  text-gray-700 hover:bg-gray-200 `}
                                      >
                                        A
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => changeDataFormat(
                                          "underline",
                                          "fontStyle"
                                        )}
                                        className={`${dataFormat.fontStyle == "underline"
                                          ? " bg-gray-200 "
                                          : ""
                                          }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 underline hover:bg-gray-50  `}
                                      >
                                        A
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox
                                value={dataFormat.cellBGColour}
                                onChange={(e) =>
                                  changeDataFormat(e, "cellBGColour")
                                }
                              >
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  Cell Background Scale Colour
                                </Listbox.Label>
                                <div className="relative mt-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="flex items-center">
                                      <span
                                        style={{
                                          backgroundColor: dataFormat.cellBGColour,
                                        }}
                                        className={classNames(
                                          "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                                        )}
                                      />
                                      <span className="ml-3 block truncate">
                                        {dataFormat.cellBGColour}
                                      </span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {colorPallate.map((color) => (
                                        <Listbox.Option
                                          key={color.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={color.color}
                                        >
                                          <div className="flex items-center">
                                            <span
                                              style={{
                                                backgroundColor: color.color,
                                              }}
                                              className={classNames(
                                                "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                                              )}
                                              aria-hidden="true"
                                            />
                                            <span
                                              className={classNames(
                                                dataFormat.cellBGColour ==
                                                  color.color
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {color.color}
                                            </span>
                                            {dataFormat.cellBGColour ==
                                              color.color ? (
                                              <span
                                                className={classNames(
                                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <button
                                type="button"
                                onClick={saveDataFormat}
                                className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
                              >
                                Save
                              </button>
                            </div>
                            <div className="sm:col-span-6">
                              <button
                                type="button"
                                className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
                              >
                                Add Child Widget
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className=" bg-gray-50  px-4 py-5  sm:p-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                              <div className="sm:col-span-6">
                                <Listbox
                                  value={dataFormula?.operatorType?.name}
                                  onChange={(e) => changeFormulaModel(e, "operatorType")}
                                >
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm font-medium text-gray-700">
                                        Choose operator type
                                      </Listbox.Label>
                                      <div className="relative mt-1">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                          <span className="flex items-center">
                                            <span className="block truncate">
                                              {dataFormula?.operatorType?.name}
                                            </span>
                                          </span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {operatorTypeList.map((item) => (
                                              <Listbox.Option
                                                key={item.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "text-white bg-indigo-600"
                                                      : "text-gray-900",
                                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={item}
                                              >
                                                {({
                                                  selectedOperatorType,
                                                  active,
                                                }) => (
                                                  <>
                                                    <div className="flex items-center">
                                                      <span
                                                        className={classNames(
                                                          dataFormat
                                                            ? "font-semibold"
                                                            : "font-normal",
                                                          "block truncate"
                                                        )}
                                                      >
                                                        {item.name}
                                                      </span>
                                                    </div>

                                                    {selectedOperatorType ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-indigo-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                              </div>
                            </div>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="first-name"
                                className="block text-xs font-bold text-gray-700"
                              >
                                Format Column Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="columnName"
                                  value={dataFormula?.newColumnName}
                                  onChange={(e) => changeFormulaModel(e?.target?.value, "newColumnName")}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox

                                value={dataFormula?.columnFrom?.chooseColumn}
                                onChange={(e) => changeFormulaModel({ columnName: e?.columnName, chooseColumn: e?.chooseColumn }, "columnFrom")}
                              >
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                                      Choose Column
                                    </Listbox.Label>
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full cursor-default rounded-md border  h-10 border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="flex items-center">
                                          <span className="block truncate">
                                            {dataFormula?.columnFrom?.columnName}
                                          </span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {columnPropCtx.headerList.map(
                                            (item) => (
                                              <Listbox.Option
                                                key={item.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "text-white bg-indigo-600"
                                                      : "text-gray-900",
                                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={item}
                                              >
                                                {({
                                                  chooseColumnFrom,
                                                  active,
                                                }) => (
                                                  <>
                                                    <div className="flex items-center">
                                                      <span
                                                        className={classNames(
                                                          dataFormat
                                                            ? "font-semibold"
                                                            : "font-normal",
                                                          "block truncate"
                                                        )}
                                                      >
                                                        {item.columnName}
                                                      </span>
                                                    </div>

                                                    {chooseColumnFrom ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-indigo-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            )
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <Listbox
                                value={dataFormula?.columnTo?.chooseColumn}
                                onChange={(e) => changeFormulaModel({ columnName: e?.columnName, chooseColumn: e?.chooseColumn }, "columnTo")}
                              >
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                                      Choose Column
                                    </Listbox.Label>
                                    <div className="relative mt-1">
                                      <Listbox.Button className="relative w-full h-10 cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="flex items-center">
                                          <span className="block truncate">
                                            {dataFormula?.columnTo?.columnName}
                                          </span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                          <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {columnPropCtx.headerList.map(
                                            (item) => (
                                              <Listbox.Option
                                                key={item.id}
                                                className={({ active }) =>
                                                  classNames(
                                                    active
                                                      ? "text-white bg-indigo-600"
                                                      : "text-gray-900",
                                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                                  )
                                                }
                                                value={item}
                                              >
                                                {({
                                                  chooseColumnTo,
                                                  active,
                                                }) => (
                                                  <>
                                                    <div className="flex items-center">
                                                      <span
                                                        className={classNames(
                                                          dataFormat
                                                            ? "font-semibold"
                                                            : "font-normal",
                                                          "block truncate"
                                                        )}
                                                      >
                                                        {item.columnName}
                                                      </span>
                                                    </div>

                                                    {chooseColumnTo ? (
                                                      <span
                                                        className={classNames(
                                                          active
                                                            ? "text-white"
                                                            : "text-indigo-600",
                                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                      >
                                                        <CheckIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            )
                                          )}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <button
                                type="button"
                                onClick={saveDataFormula}
                                className="inline-flex justify-center text-center items-center w-full rounded-md border-2 border-primary bg-white px-3 py-2 text-sm font-medium leading-4 text-primary shadow-sm hover:bg-gray-50 focus:outline-none"
                              >
                                Save
                              </button>
                            </div>
                          </div>

                        )}
                      </form>
                    ) : (
                      <ZPermissionConfig permissionList={permissionList} />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ZColumnProperties;
