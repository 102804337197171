import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ZButton from "../Button/ZButton";
import Calendar from "../Calendar/Calendar";
import ZGanttChart from "../GanttChart/ZGanttChart";
import ZMap from "../Map/ZMap";
import ZTable from "../Table/ZTable";
import ZCircularButton from "../Button/ZCircularButton";
import ParentGanttChart from "../GanttChart/ParentGanttChart";
import ParentCalendar from "../Calendar/ParentCalendar";

const tabs = [
  { name: "Button", path: "/" },
  { name: "Calendar", path: "/calendar" },
  { name: "GanttChart", path: "/ganttchart" },
  { name: "Map", path: "/map" },
  { name: "Table", path: "/table" },
];

const btnprops = {
  text: {
    type: String,
    default: 'button text'
  },
  size: {
    type: String,
    default: 'md'  //xs | sm | md | lg | xl
  },
  varient: {
    type: String,
    default: 'primary' // plain | primary | secondary
  },
  icon: {
    type: String,
    default: 'EnvelopeIcon'
  },
  iconPlacement: {
    type: String,
    default: 'prefix' //prefix | suffix  
  },
  isRoundButton: {
    type: Boolean,
    default: true
  }
};

const circularProps = {
  size: {
    type: String,
    default: 'md'  //xs | sm | md | lg | xl
  },
  varient: {
    type: String,
    default: 'secondary' // plain | primary | secondary
  },
  icon: {
    type: String,
    default: 'EnvelopeIcon'
  }
};

const points = {
  //type it will be deside the marker design (1.type:'circle' -it wil be show a circle marker.| 2.type:'pin' -it wil be show a location pin  marker)
  type: "circle", //|circle | pin
  // varient define the map category  (1.'standAlone'-It contains only one prop and shows a location with a description.| 2.'X-points'-It contains multiple props, so it shows multiple locations with a description.|3.'distance'-It has two props and displays the distance between them. )
  varient: "standAlone",  //standAlone | X-points|distance
  content: [
    {
      id: 1,
      title: "Description1", //Give about location description
      lat: 13.067439, // lat value
      lng: 80.237617, // lng value
    },
    {
      id: 2,
      title: "Description2",
      lat: 10.067439,
      lng: 80.237617,
    },
    {
      id: 3,
      title: "Description",
      lat: 10.067439,
      lng: 75.237617,
    },
    {
      id: 4,
      title: "Description",
      lat: 11.067439,
      lng: 72.237617,
    },
    {
      id: 5,
      title: "Description",
      lat: 13.067439,
      lng: 69.237617,
    },
  ],
};

const ZComponents = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <BrowserRouter>
      <div className="border-b mt-2 border-gray-200">
        <div className="px-6">
          <nav className="-mb-px flex space-x-6 text-md">
            {tabs.map((tab, index) => (
              <Link
                to={tab.path}
                key={tab.name}
                onClick={() => setCurrentTab(index)}
                className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-md cursor-pointer${
                  index === currentTab
                    ? "border border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="my-3">
        <Routes>
          <Route
            path="/"
            element={<><ZButton props={btnprops} /> 
            <ZCircularButton circleProps={circularProps} />
            </>}
          />
          <Route
            path="/calendar"
            element={<ParentCalendar />}
          />
          <Route
            path="/ganttchart"
            element={<ParentGanttChart />}
          />
          <Route
            path="/map"
            element={<ZMap points={points} />}
          />
          <Route
            path="/table"
            element={<ZTable />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default ZComponents;
