import { Transition, Listbox, Popover } from "@headlessui/react";
import {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import ZColorPicker from "./ZColorPicker";
import TableContext from "../../store/TableContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const colorPallate = [
  {
    id: 1,
    color: "#12B76A",
  },
  {
    id: 2,
    color: "#0BA5EC",
  },
  {
    id: 3,
    color: "#6172F3",
  },
  {
    id: 4,
    color: "#7A5AF8",
  },
  {
    id: 5,
    color: "#9E77ED",
  },
  {
    id: 6,
    color: "#4E5BA6",
  },
  {
    id: 7,
    color: "#EE46BC",
  },
  {
    id: 8,
    color: "#F63D68",
  },
  {
    id: 9,
    color: "#FB6514",
  },
  {
    id: 10,
    color: "#D1FADF",
  },
  {
    id: 11,
    color: "#E0F2FE",
  },
  {
    id: 12,
    color: "#E0EAFF",
  },
  {
    id: 13,
    color: "#EBE9FE",
  },
  {
    id: 14,
    color: "#F4EBFF",
  },
  {
    id: 15,
    color: "#EAECF5",
  },
  {
    id: 16,
    color: "#FCE7F6",
  },
  {
    id: 17,
    color: "#FFE4E8",
  },
  {
    id: 18,
    color: "#FFEAD5",
  },
  {
    id: 19,
    color: "#A6F4C5",
  },
  {
    id: 20,
    color: "#B9E6FE",
  },
  {
    id: 21,
    color: "#C7D7FE",
  },
  {
    id: 22,
    color: "#D9D6FE",
  },
  {
    id: 23,
    color: "#E9D7FE",
  },
  {
    id: 24,
    color: "#D5D9EB",
  },
  {
    id: 25,
    color: "#FCCEEE",
  },
  {
    id: 26,
    color: "#FECDD6",
  },
  {
    id: 27,
    color: "#FDDCAB",
  },
  {
    id: 28,
    color: "#6CE9A6",
  },
  {
    id: 29,
    color: "#7CD4FD",
  },
  {
    id: 30,
    color: "#A4BCFD",
  },
  {
    id: 31,
    color: "#BDB4FE",
  },
  {
    id: 32,
    color: "#D6BBFB",
  },
  {
    id: 33,
    color: "#AFB5D9",
  },
  {
    id: 34,
    color: "#FAA7E0",
  },
  {
    id: 35,
    color: "#FEA3B4",
  },
  {
    id: 36,
    color: "#FEB273",
  },
  {
    id: 37,
    color: "#32D583",
  },
  {
    id: 38,
    color: "#36BFFA",
  },
  {
    id: 39,
    color: "#8098F9",
  },
  {
    id: 40,
    color: "#9B8AFB",
  },
  {
    id: 41,
    color: "#B692F6",
  },
  {
    id: 42,
    color: "#717BBC",
  },
  {
    id: 43,
    color: "#F670C7",
  },
  {
    id: 44,
    color: "#FD6F8E",
  },
  {
    id: 45,
    color: "#FD853A",
  },
  {
    id: 46,
    color: "#12B76A",
  },
  {
    id: 47,
    color: "#0BA5EC",
  },
  {
    id: 48,
    color: "#6172F3",
  },
  {
    id: 49,
    color: "#7A5AF8",
  },
  {
    id: 50,
    color: "#9E77ED",
  },
  {
    id: 51,
    color: "#4E5BA6",
  },
  {
    id: 52,
    color: "#EE46BC",
  },
  {
    id: 53,
    color: "#F63D68",
  },
  {
    id: 54,
    color: "#FB6514",
  },
  {
    id: 55,
    color: "#039855",
  },
  {
    id: 56,
    color: "#0086C9",
  },
  {
    id: 57,
    color: "#444CE7",
  },
  {
    id: 58,
    color: "#6938EF",
  },
  {
    id: 59,
    color: "#7F56D9",
  },
  {
    id: 60,
    color: "#3E4784",
  },
  {
    id: 61,
    color: "#DD2590",
  },
  {
    id: 62,
    color: "#E31B54",
  },
  {
    id: 63,
    color: "#EC4A0A",
  },
  {
    id: 64,
    color: "#027A48",
  },
  {
    id: 65,
    color: "#026AA2",
  },
  {
    id: 66,
    color: "#3538CD",
  },
  {
    id: 67,
    color: "#5925DC",
  },
  {
    id: 68,
    color: "#6941C6",
  },
  {
    id: 69,
    color: "#363F72",
  },
  {
    id: 70,
    color: "#C11574",
  },
  {
    id: 71,
    color: "#C01048",
  },
  {
    id: 72,
    color: "#C4320A",
  },
  {
    id: 73,
    color: "#05603A",
  },
  {
    id: 74,
    color: "#1849A9",
  },
  {
    id: 75,
    color: "#2D31A6",
  },
  {
    id: 76,
    color: "#4A1FB8",
  },
  {
    id: 77,
    color: "#53389E",
  },
  {
    id: 78,
    color: "#293056",
  },
  {
    id: 79,
    color: "#9E165F",
  },
  {
    id: 80,
    color: "#A11043",
  },
  {
    id: 81,
    color: "#9C2A10",
  },
  {
    id: 82,
    color: "#054F31",
  },
  {
    id: 83,
    color: "#194185",
  },
  {
    id: 84,
    color: "#2D3282",
  },
  {
    id: 85,
    color: "#3E1C96",
  },
  {
    id: 86,
    color: "#42307D",
  },
  {
    id: 87,
    color: "#101323",
  },
  {
    id: 88,
    color: "#851651",
  },
  {
    id: 89,
    color: "#89123E",
  },
  {
    id: 90,
    color: "#7E2410",
  },
  {
    id: 91,
    color: "transparant",
  },
];

const ZGeneralConfig = forwardRef((_, ref) => {
  const ctx = useContext(TableContext);
  let generalInterface = ctx.commonConfig
    ? ctx.commonConfig
    : {
        tableName: "",
        color: "",
        fontSize: "",
        fontStyle: "",
        description: "",
        columnColour: "",
        columnFontSize: "",
        columnFontStyle: "",
        columnHeaderBG: "",
        columnBG: "",
        rowColor: "",
        rowFontSize: "",
        rowFontStyle: "",
        isRowAlternation: false,
        isEditPermission: false,
        isPagination: false,
        isPageAsScroll: false,
        isAutoPage: false,
        isSearch: false,
        isExport: false,
        isAdvancedFilter: false,
        isAddNew: false,
      };
  const [generalForm, setForm] = useState(generalInterface);
  const [isOpenColor, setOpenColor] = useState(false);
  const [isOpenColumnColor, setOpenColumnColor] = useState(false);
  const [isOpenRowColor, setOpenRowColor] = useState(false);

  useImperativeHandle(ref, (category) => ({
    onTriggerRefData: onTriggerRefData,
  }));

  const onTriggerRefData = (category) => {
    if (category == "onClose") ctx.addCommonConfig(generalForm);
  };

  const openColorHandler = (keyParam) => () => {
    if (keyParam == "color") {
      setOpenColor(true);
    } else if (keyParam == "columnColour") {
      setOpenColumnColor(true);
    } else if (keyParam == "rowColor") {
      setOpenRowColor(true);
    }
  };

  const updateStyles = (styleProperty, value) => () => {
    setForm({
      ...generalForm,
      [styleProperty]: value,
    });
  };

  const OnHandleSelectedColor = (pickedColor, keyParam) => {
    setForm({
      ...generalForm,
      [keyParam]: pickedColor,
    });
    if (keyParam == "color") {
      setOpenColor(false);
    } else if (keyParam == "columnColour") {
      setOpenColumnColor(false);
    } else if (keyParam == "rowColor") {
      setOpenRowColor(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("commonconfig", JSON.stringify(generalForm));
  }, [generalForm]);

  return (
    <form className="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5  sm:p-6">
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="first-name"
                className="block text-xs font-bold text-gray-700"
              >
                Table Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="table-name"
                  value={generalForm.tableName}
                  onChange={(e) =>
                    setForm({ ...generalForm, tableName: e.target.value })
                  }
                  placeholder="Give any name to your data table"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="flex justify-between">
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Colour
                  </label>
                  <div className="mt-1">
                    <span className="  inline-flex rounded-md shadow-sm">
                      <Popover className="relative">
                        <Popover.Button>
                          <span
                            onClick={openColorHandler("color")}
                            className="relative rounded-md h-10 -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                          >
                            <div
                              value={generalForm.columnColour}
                              onClick={openColorHandler("color")}
                              style={{
                                backgroundColor: generalForm.color,
                              }}
                              className={`w-4 h-4 rounded-full bg-primary cursor-pointer border hover:border-gray-800`}
                            ></div>
                          </span>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          show={isOpenColor}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute    left-1/2 z-10 mt-3 w-56 transform">
                            <ZColorPicker
                              colorList={colorPallate}
                              selectedColor={generalForm.color}
                              keyParam="color"
                              onHandleSelectedColor={OnHandleSelectedColor}
                            />
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Size
                  </label>
                  <div className="mt-1">
                    <span
                      className="inline-flex rounded-md shadow-sm"
                      style={{ maxHeight: "38px" }}
                    >
                      <button
                        type="button"
                        style={{ fontSize: "10px" }}
                        onClick={updateStyles("fontSize", "10px")}
                        className={classNames(
                          generalForm.fontSize == "10px" ? "bg-gray-200 " : "",
                          "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2  font-medium  text-gray-700 hover:bg-gray-200  "
                        )}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("fontSize", "14px")}
                        className={`${
                          generalForm.fontSize == "14px" ? "bg-gray-200 " : ""
                        }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("fontSize", "18px")}
                        className={`"${
                          generalForm.fontSize == "18px" ? " bg-gray-200 " : ""
                        }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Style
                  </label>
                  <div className="mt-1">
                    <span className=" inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        onClick={updateStyles("fontStyle", "italic")}
                        className={`${
                          generalForm.fontStyle == "italic"
                            ? "bg-gray-200 "
                            : ""
                        }relative inline-flex items-center rounded-l-md border italic border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("fontStyle", "bold")}
                        className={`${
                          generalForm.fontStyle == "bold" ? "bg-gray-200 " : ""
                        }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-bold  text-gray-700 hover:bg-gray-200 `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("fontStyle", "underline")}
                        className={`${
                          generalForm.fontStyle == "underline"
                            ? " bg-gray-200 "
                            : ""
                        }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 underline hover:bg-gray-50  `}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="about"
                className="block text-xs font-bold text-gray-700"
              >
                Description
              </label>
              <div className="mt-1">
                <textarea
                  name="description"
                  value={generalForm.description}
                  onChange={(e) =>
                    setForm({ ...generalForm, description: e.target.value })
                  }
                  rows={4}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm mt-4 font-bold text-gray-700"
          >
            Column Properties
          </label>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="flex justify-between">
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Colour
                  </label>
                  <div className="mt-1">
                    <span className="  inline-flex rounded-md shadow-sm">
                      <Popover className="relative">
                        <Popover.Button>
                          <span
                            onClick={openColorHandler("columnColour")}
                            className="relative h-10 rounded-md -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                          >
                            <div
                              value={generalForm.columnColour}
                              onClick={openColorHandler("columnColour")}
                              style={{
                                backgroundColor: generalForm.columnColour,
                              }}
                              className={`w-4 h-4 rounded-full bg-primary cursor-pointer border hover:border-gray-800`}
                            ></div>
                          </span>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          show={isOpenColumnColor}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute    left-1/2 z-10 mt-3 w-56 transform">
                            <ZColorPicker
                              colorList={colorPallate}
                              selectedColor={generalForm.columnColour}
                              keyParam="columnColour"
                              onHandleSelectedColor={OnHandleSelectedColor}
                            />
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Size
                  </label>
                  <div className="mt-1">
                    <span
                      className="inline-flex rounded-md shadow-sm"
                      style={{ maxHeight: "38px" }}
                    >
                      <button
                        type="button"
                        style={{ fontSize: "10px" }}
                        onClick={updateStyles("columnFontSize", "10px")}
                        className={classNames(
                          generalForm.columnFontSize == "10px"
                            ? "bg-gray-200"
                            : "",
                          "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2  font-medium text-gray-700 hover:bg-gray-200  "
                        )}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("columnFontSize", "14px")}
                        className={classNames(
                          generalForm.columnFontSize == "14px"
                            ? " bg-gray-200"
                            : "",
                          "relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                        )}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("columnFontSize", "18px")}
                        className={classNames(
                          generalForm.columnFontSize == "18px"
                            ? " bg-gray-200"
                            : "",
                          "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium  text-gray-700 hover:bg-gray-200  "
                        )}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Style
                  </label>
                  <div className="mt-1">
                    <span className=" inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        onClick={updateStyles("columnFontStyle", "italic")}
                        className={classNames(
                          generalForm.columnFontStyle == "italic"
                            ? " bg-gray-200"
                            : "",
                          "relative inline-flex items-center rounded-l-md border italic border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                        )}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("columnFontStyle", "bold")}
                        className={classNames(
                          generalForm.columnFontStyle == "bold"
                            ? " bg-gray-200"
                            : "",
                          "relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-bold  text-gray-700 hover:bg-gray-200 focus:z-10 focus:border-indigo-500  focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        )}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("columnFontStyle", "underline")}
                        className={classNames(
                          generalForm.columnFontStyle == "underline"
                            ? " bg-gray-200"
                            : "",
                          "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 underline hover:bg-gray-50  "
                        )}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <Listbox
                value={generalForm.columnHeaderBG}
                onChange={(e) => setForm({ ...generalForm, columnHeaderBG: e })}
              >
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  Column Hearder Background Colour
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span
                        style={{ backgroundColor: generalForm.columnHeaderBG }}
                        className={classNames(
                          "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                        )}
                      />
                      <span className="ml-3 block truncate">
                        {generalForm.columnHeaderBG}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {colorPallate.map((color) => (
                        <Listbox.Option
                          key={color.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={color.color}
                        >
                          <div className="flex items-center">
                            <span
                              style={{
                                backgroundColor: color.color,
                              }}
                              className={classNames(
                                "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                generalForm.columnHeaderBG == color.color
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {color.color}
                            </span>
                            {generalForm.columnHeaderBG == color.color ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm mt-4 font-bold text-gray-700"
          >
            Row Properties
          </label>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="flex justify-between">
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Colour
                  </label>
                  <div className="mt-1">
                    <span className="  inline-flex rounded-md shadow-sm">
                      <Popover className="relative">
                        <Popover.Button>
                          <span
                            onClick={openColorHandler("rowColor")}
                            className="relative h-10 rounded-md -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  "
                          >
                            <div
                              value={generalForm.rowColor}
                              style={{ backgroundColor: generalForm.rowColor }}
                              className={`w-4 h-4 rounded-full bg-primary cursor-pointer border hover:border-gray-800`}
                            ></div>
                          </span>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          show={isOpenRowColor}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute    left-1/2 z-10 mt-3 w-56 transform">
                            <ZColorPicker
                              colorList={colorPallate}
                              selectedColor={generalForm.rowColor}
                              keyParam="rowColor"
                              onHandleSelectedColor={OnHandleSelectedColor}
                            />
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Size
                  </label>
                  <div className="mt-1">
                    <span
                      className="inline-flex rounded-md shadow-sm"
                      style={{ maxHeight: "38px" }}
                    >
                      <button
                        type="button"
                        style={{ fontSize: "10px" }}
                        onClick={updateStyles("rowFontSize", "10px")}
                        className={`${
                          generalForm.rowFontSize == "10px"
                            ? " bg-gray-200 "
                            : ""
                        }relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2  font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("rowFontSize", "14px")}
                        className={`${
                          generalForm.rowFontSize == "14px"
                            ? " bg-gray-200 "
                            : ""
                        }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("rowFontSize", "18px")}
                        className={`${
                          generalForm.rowFontSize == "18px"
                            ? " bg-gray-200 "
                            : ""
                        }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-xs font-bold text-gray-700"
                  >
                    Font Style
                  </label>
                  <div className="mt-1">
                    <span className=" inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        onClick={updateStyles("rowFontStyle", "italic")}
                        className={`${
                          generalForm.rowFontStyle == "italic"
                            ? " bg-gray-200 "
                            : ""
                        }relative inline-flex items-center rounded-l-md border italic border-gray-300 bg-white px-4 py-2 text-sm font-medium  text-gray-700 hover:bg-gray-200  `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("rowFontStyle", "bold")}
                        className={`${
                          generalForm.rowFontStyle == "bold"
                            ? " bg-gray-200 "
                            : ""
                        }relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-bold  text-gray-700 hover:bg-gray-200 `}
                      >
                        A
                      </button>
                      <button
                        type="button"
                        onClick={updateStyles("rowFontStyle", "underline")}
                        className={`${
                          generalForm.rowFontStyle == "underline"
                            ? " bg-gray-200 "
                            : ""
                        }relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 underline hover:bg-gray-50  `}
                      >
                        A
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <Listbox
                value={generalForm.columnBG}
                onChange={(e) => setForm({ ...generalForm, columnBG: e })}
              >
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  Row Background Colour
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span
                        style={{ backgroundColor: generalForm.columnBG }}
                        className={classNames(
                          "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                        )}
                      />
                      <span className="ml-3 block truncate">
                        {generalForm.columnBG}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {colorPallate.map((color) => (
                        <Listbox.Option
                          key={color.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={color.color}
                        >
                          <div className="flex items-center">
                            <span
                              style={{
                                backgroundColor: color.color,
                              }}
                              className={classNames(
                                "inline-block h-4 w-4 flex-shrink-0 rounded-full"
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                generalForm.columnBG == color.color
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {color.color}
                            </span>
                            {generalForm.columnBG == color.color ? (
                              <span
                                className={classNames(
                                  "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isRowAlternation"
                    type="checkbox"
                    checked={generalForm.isRowAlternation}
                    onChange={(e) =>
                      setForm({
                        ...generalForm,
                        isRowAlternation: e.target.checked,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Row Alternation colour
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isEditPermission"
                    type="checkbox"
                    checked={generalForm.isEditPermission}
                    onChange={(e) =>
                      setForm({
                        ...generalForm,
                        isEditPermission: e.target.checked,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Edit Permissions
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm mt-4 font-bold text-gray-700"
          >
            Pagination Properties
          </label>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isPagination"
                    type="checkbox"
                    checked={generalForm.isPagination}
                    onChange={(e) =>
                      setForm({
                        ...generalForm,
                        isPagination: e.target.checked,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Add Pagination
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isPageAsScroll"
                    type="checkbox"
                    checked={generalForm.isPageAsScroll}
                    onChange={(e) =>
                      setForm({
                        ...generalForm,
                        isPageAsScroll: e.target.checked,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Lazy Load As you Scroll
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isAutoPage"
                    type="checkbox"
                    checked={generalForm.isAutoPage}
                    onChange={(e) =>
                      setForm({ ...generalForm, isAutoPage: e.target.checked })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Auto Page Timer
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm mt-4 font-bold text-gray-700"
          >
            Actionable Properties
          </label>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isAddNew"
                    type="checkbox"
                    checked={generalForm.isAddNew}
                    onChange={(e) =>
                      setForm({ ...generalForm, isAddNew: e.target.checked })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Add new button
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isAdvancedFilter"
                    type="checkbox"
                    checked={generalForm.isAdvancedFilter}
                    onChange={(e) =>
                      setForm({
                        ...generalForm,
                        isAdvancedFilter: e.target.checked,
                      })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Filter icon button
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isExport"
                    type="checkbox"
                    checked={generalForm.isExport}
                    onChange={(e) =>
                      setForm({ ...generalForm, isExport: e.target.checked })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Export Option
                  </label>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    name="isSearch"
                    type="checkbox"
                    checked={generalForm.isSearch}
                    onChange={(e) =>
                      setForm({ ...generalForm, isSearch: e.target.checked })
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Search Bar
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default ZGeneralConfig;
