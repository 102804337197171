import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./ZMap.css";

const ZMap = ({ points }) => {
  var finalprops = [];
  if (points.varient == "distance") {
    for (let i = 0; i <= 1; i++) {
      finalprops.push(points.content[i]);
    }
  } else if (points.varient == "standAlone") {
    for (let i = 0; i < 1; i++) {
      finalprops.push(points.content[i]);
    }
  } else {
    for (let i = 0; i < points.content.length; i++) {
      finalprops.push(points.content[i]);
    }
  }

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {finalprops.map(({ lat, lng, id, title }) => {
          return (
            <MyMarker
              key={id}
              lat={lat}
              lng={lng}
              text={title}
              tooltip={title}
              data={points}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

const MyMarker = ({ text, tooltip, $hover, data }) => {
  const [km, setKm] = useState();
  useEffect(() => {
    handleClick();
  }, [data.varient,data.type]);

  const handleClick = () => {
    if (data.varient === "distance") {
      const p1 = data.content[0];
      const p2 = data.content[1];
      var R = 6378137;
      var dLat = rad(p2.lat - p1.lat);
      var dLong = rad(p2.lng - p1.lng);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) *
          Math.cos(rad(p2.lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      var km = Math.round(d / 1000);
      setKm(km);
      return km;
    } else  {
      setKm("");
    }
  };

  const rad = (x) => {
    return (x * Math.PI) / 180;
  };

  return (
    <div>
      <div className={$hover ? "circle hover" : "circle"}>
        <span className="circleText">
          <div className="tooltip">
            <div className={data.type === "circle" ? "marker" : "pin"}>
              {data.type === "circle"?<div className="out-blink"></div>:''}
            </div>
            <div className="top">
              <h2> Info</h2>
              <p> {text}</p>
              <b>{km != "" && km != undefined ? km + "Km" : ""}</b>
              <i></i>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default ZMap;
