import { Popover, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import TableContext from "../../store/TableContext";
import chevDowIcon from "../../assets/icons/chevron-down.svg";
import XIcon from "../../assets/icons/x.svg";

const checkboxFilterList = [
  {
    id: 1,
    name: "Checked",
  },
  {
    id: 2,
    name: "Unchecked",
  },
];

const statusFilterList = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Inactive",
  },
];

const checkboxFilterOptions = [
  {
    id: 1,
    name: "Is",
  },
  {
    id: 2,
    name: "Is not",
  },
];

const dateFilterOptions = [
  {
    id: 1,
    name: "Is",
  },
  {
    id: 2,
    name: "Is before",
  },
  {
    id: 3,
    name: "Is after",
  },
  {
    id: 4,
    name: "Is on or before",
  },
  {
    id: 5,
    name: "Is on or after",
  },
  {
    id: 6,
    name: "Is within",
  },
  {
    id: 7,
    name: "Is empty",
  },
  {
    id: 8,
    name: "Is not empty",
  },
];

const numberFilterOptions = [
  {
    id: 1,
    name: "=",
  },
  {
    id: 1,
    name: "!=",
  },
  {
    id: 1,
    name: ">",
  },
  {
    id: 1,
    name: "<",
  },
  {
    id: 1,
    name: ">=",
  },
  {
    id: 1,
    name: "<=",
  },
  {
    id: 1,
    name: "Is empty",
  },
  {
    id: 1,
    name: "Is not empty",
  },
];

const statusFilterOptions = [
  {
    id: 1,
    name: "Is",
  },
  {
    id: 1,
    name: "Is not",
  },
  {
    id: 1,
    name: "Is empty",
  },
  {
    id: 1,
    name: "Is not empty",
  },
];

const textFilterOptions = [
  {
    id: 1,
    name: "Is",
  },
  {
    id: 2,
    name: "Is not",
  },
  {
    id: 3,
    name: "Contains",
  },
  {
    id: 4,
    name: "Does not contain",
  },
  {
    id: 5,
    name: "Stats with",
  },
  {
    id: 6,
    name: "Ends with",
  },
  {
    id: 7,
    name: "Is empty",
  },
  {
    id: 8,
    name: "Is not empty",
  },
];

const ZAdvancedFilter = (props) => {
  const ctx = useContext(TableContext);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [column, setColumn] = useState([...ctx.headerList]);
  const [advancedFilterOption, setAdvancedFilterOption] = useState(
    ctx.advancedFilterData
  );
  const [rawFilterList, setRawFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);

  useEffect(() => {
    setAdvancedFilterOption(ctx.advancedFilterData);
    onSelectCtxHandler(ctx.advancedFilterData);
  }, [ctx]);

  const onSelectCtxHandler = ({ columnName }) => {
    if (columnName) {
      column.forEach((item) => {
        if (item.chooseColumn.toLowerCase() === columnName.toLowerCase()) {
          onSelectFilter(item);
        }
      });
    }
  };

  const onSelectFilter = (item) => {
    let obj = {
      name: item.columnName,
      icon: item.chooseColumnIcon,
      type: item.chooseColumnType,
      value: "",
    };
    switch (item.chooseColumnType) {
      case "text":
        obj["optionList"] = textFilterOptions;
        obj["rule"] = textFilterOptions[0].name;
        break;
      case "status":
        obj["optionList"] = statusFilterOptions;
        obj["rule"] = statusFilterOptions[0].name;
        break;
      case "number":
        obj["optionList"] = numberFilterOptions;
        obj["rule"] = numberFilterOptions[0].name;
        break;
      case "date":
        obj["optionList"] = dateFilterOptions;
        obj["rule"] = dateFilterOptions[0].name;
        break;
      case "checkbox":
        obj["optionList"] = checkboxFilterOptions;
        obj["rule"] = checkboxFilterOptions[0].name;
        break;

      default:
        break;
    }
    setRawFilterList([...rawFilterList, obj]);
    onRemoveColumn(obj);
  };

  const onRemoveColumn = (obj) => {
    column.forEach((item, index) => {
      if (item.columnName.toLowerCase() === obj.name.toLowerCase()) {
        column.splice(index, 1);
      }
    });
    setColumn([...column]);
  };

  const onRemoveFilterHandler = (list, index) => () => {
    let refFilterList = rawFilterList;
    refFilterList.splice(index, 1);
    setRawFilterList([...refFilterList]);
    let refHeadList = ctx.headerList;
    refHeadList.forEach((item) => {
      if (item.columnName.toLowerCase() === list.name.toLowerCase()) {
        setColumn([...column, item]);
      }
    });
  };

  const onSelectFilterOption = (item, list) => () => {
    list["rule"] = item.name;
    rawFilterList.forEach((item) => {
      if (item.name === list.name) {
        item = list;
      }
    });
    setRawFilterList([...rawFilterList]);
  };

  return (
    <div className="relative flex items-center min-w-full  border border-gray-50 rounded mt-3">
      {rawFilterList &&
        rawFilterList.map((list, index) => {
          return (
            <Popover className="relative">
              {({ isOpenFilter }) => (
                <>
                  <Popover.Button>
                    <span
                      key={`selected--filter--${index}`}
                      className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800"
                    >
                      {list.icon && (
                        <img src={list.icon} className="w-3 mr-2" alt="" />
                      )}
                      {list.name}
                      <img src={chevDowIcon} className="w-3 ml-2" alt="" />
                    </span>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute mt-2 z-50 w-60 transform">
                      <div className="relative grid grid-cols-6 px-2 py-2 rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                        <div className="sm:col-span-6 mb-3 flex justify-between ">
                          <div className="flex">
                            <span className="text-sm text-gray-500">
                              {list.name}
                            </span>
                            <Popover className="relative">
                              {({ isOpenFilter }) => (
                                <>
                                  <Popover.Button>
                                    <span className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800 cursor-pointer hover:bg-gray-300">
                                      {list.rule}
                                      <img
                                        src={chevDowIcon}
                                        className="w-3 ml-2"
                                        alt=""
                                      />
                                    </span>
                                  </Popover.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <Popover.Panel className="absolute mt-2 z-50 w-60 transform">
                                      <div className="relative grid grid-cols-6 px-2 py-2 rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                                        {list &&
                                          list.optionList &&
                                          list.optionList.map((item, index) => {
                                            return (
                                              <div
                                                className="sm:col-span-6"
                                                onClick={onSelectFilterOption(
                                                  item,
                                                  list
                                                )}
                                              >
                                                <a
                                                  key={`filter--list--${index}}`}
                                                  className="flex rounded-mdv cursor-pointer p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                                >
                                                  <p className="text-sm text-gray-500">
                                                    {item.name}
                                                  </p>
                                                </a>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          </div>
                          <button
                            onClick={onRemoveFilterHandler(list, index)}
                            className="rounded-md border p-1 border-gray-300 bg-white text-gray-400 hover:text-gray-500"
                          >
                            <img src={XIcon} className="w-3" alt="" />
                          </button>
                        </div>
                        {list.type == "checkbox" ? (
                          <>
                            {checkboxFilterOptions.map((item, index) => {
                              return (
                                <div
                                  className="sm:col-span-6"
                                  //   onClick={onSelectFilter(item)}
                                >
                                  <a
                                    key={`filter--list--${index}}`}
                                    className="flex rounded-mdv cursor-pointer p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                  >
                                    <p className="text-sm text-gray-500">
                                      {item.name}
                                    </p>
                                  </a>
                                </div>
                              );
                            })}
                          </>
                        ) : list.type == "status" ? (
                          <>
                            {statusFilterList.map((item, index) => {
                              return (
                                <div
                                  className="sm:col-span-6"
                                  //   onClick={onSelectFilter(item)}
                                >
                                  <a
                                    key={`filter--list--${index}}`}
                                    className="flex rounded-mdv cursor-pointer p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                                  >
                                    <input
                                      name="isRowAlternation"
                                      type="checkbox"
                                      className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <p className="text-sm text-gray-500">
                                      {item.name}
                                    </p>
                                  </a>
                                </div>
                              );
                            })}
                          </>
                        ) : list.type == "date" ? (
                          <div className="sm:col-span-6">
                            <input
                              type="date"
                              name="table-name"
                              placeholder="DD/MM/YYYY"
                              className="block w-full h-8 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        ) : (
                          <div className="sm:col-span-6 mb-3">
                            <div className="relative flex items-center">
                              <input
                                type="text"
                                name="table-name"
                                placeholder="Filter by..."
                                className="block w-full h-8 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-sm font-medium text-gray-400">
                                  <img src={XIcon} className="w-3" alt="" />
                                </kbd>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          );
        })}
      {column && column.length > 0 && (
        <Popover className="relative">
          {({ isOpenFilter }) => (
            <>
              <Popover.Button>
                <span
                  onClick={() => {
                    setOpenFilter(!isOpenFilter);
                  }}
                >
                  <span className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800 cursor-pointer hover:bg-gray-300">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M5.99967 1.33325V10.6666M1.33301 5.99992H10.6663"
                        stroke="#667085"
                      />
                    </svg>
                    Add Filter
                  </span>
                </span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute mt-2 z-50 w-60 transform">
                  <div className="relative grid grid-cols-6 px-2 py-2 rounded-lg shadow-lg ring-1 bg-white ring-black ring-opacity-5">
                    <div className="sm:col-span-6 mb-3">
                      <input
                        type="text"
                        name="table-name"
                        placeholder="Filter by..."
                        className="block w-full h-8 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {column.map((item, index) => {
                      return (
                        <div
                          className="sm:col-span-6"
                          onClick={() => onSelectFilter(item)}
                        >
                          <a
                            key={`filter--list--${index}}`}
                            className="flex rounded-mdv cursor-pointer p-2 transition duration-150 ease-in-out hover:bg-gray-50"
                          >
                            {item.chooseColumnIcon ? (
                              <>
                                <img
                                  src={item.chooseColumnIcon}
                                  className="mr-3"
                                  alt=""
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            <p className="text-sm text-gray-500">
                              {item.columnName}
                            </p>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </div>
  );
};

export default ZAdvancedFilter;
